import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import Informes from '../../componentes/formulario/informes/Informes';


import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost, getEscalaTemporal, MiReplaceAll } from '../../helpers/helperApp';


function Alarmasmo(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectMultipleFormPrin, handleClonePrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    const [ optionsSensor, saveOptionsSensor] = useState();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{         
                    document.title =confPantalla.config.trad.alarmas.title+" - "+confPantalla.argosAlarmas.alarmasmo.title;                               
                    if( respuesta == 1 ){
                        saveTieneNivel(true); 
                        saveEstadoFormPrin(confPantalla.argosAlarmas.alarmasmo.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.argosAlarmas.alarmasmo.hijo.estadoForm)                               
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.argosAlarmas.alarmasmo.padre.urls);
                                saveFormularioPrin(confPantalla.argosAlarmas.alarmasmo.padre.modelform);
                                saveFormularioHijo(confPantalla.argosAlarmas.alarmasmo.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.argosAlarmas.alarmasmo.padre.urls, confPantalla.argosAlarmas.alarmasmo.hijo.urls, confPantalla.argosAlarmas.alarmasmo.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo); 
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();    
        } 
        
    },[confPantalla])
    

    const updateStateForm = (selectedItem, nameOfComponent) => {

        let textos, textosR, condicion;

        if( selectedItem ){
            if( nameOfComponent.name == "condicion" ){
                condicion = selectedItem.value;
            }else{
                condicion = formularioPrin.condicion;
            }
            if( nameOfComponent.name == "plantilla" ){
                saveFormularioPrin({...formularioPrin, 
                                    "descripcion": "",
                                    "ok": "",
                                    "okr":"",
                                    "ko": "",
                                    "kor":"",
                                    "condicion" : "",
                                    "refsupinf" : "",
                                    "rangomin" : "",
                                    "rangomax" : "",
                                    [nameOfComponent.name] : selectedItem.value
                                    })   
            }else{
                textos = getTextos(condicion, false, nameOfComponent.name, selectedItem.value);
                textosR = getTextos(condicion, true, nameOfComponent.name, selectedItem.value);
                saveFormularioPrin({...formularioPrin, 
                                    "descripcion": textosR.descripcion,
                                    "ok": textos.ok,
                                    "okr":textosR.ok,
                                    "ko": textos.ko,
                                    "kor":textosR.ko,
                                    [nameOfComponent.name] : selectedItem.value
                                    })            
            }
        }else{
            if( nameOfComponent.name == "condicion" ){
                condicion = "";
            }else{
                condicion = formularioPrin.condicion;
            }                         
            if( nameOfComponent.name == "plantilla" ){
                saveFormularioPrin({...formularioPrin, 
                                    "descripcion": "",
                                    "ok": "",
                                    "okr":"",
                                    "ko": "",
                                    "kor":"",
                                    "condicion" : "",
                                    "refsupinf" : "",
                                    "rangomin" : "",
                                    "rangomax" : "",
                                    [nameOfComponent.name] : ""
                                    })   
            }else{
                textos = getTextos(condicion, false, nameOfComponent.name, "");
                textosR = getTextos(condicion, true, nameOfComponent.name, "");
                saveFormularioPrin({...formularioPrin, 
                                    "descripcion": textosR.descripcion,
                                    "ok": textos.ok,
                                    "okr":textosR.ok,
                                    "ko": textos.ko,
                                    "kor":textosR.ko,
                                    [nameOfComponent.name] : ""
                                    })            
            }
        }
    }

    const handleInputOkKo = (e, formularioPrin, saveFormularioPrin) => {
        let textos, textosR;
 
        textos = getTextos(formularioPrin.condicion, false, e.target.name, e.target.value);
        textosR = getTextos(formularioPrin.condicion, true, e.target.name, e.target.value);
        saveFormularioPrin({...formularioPrin, 
                            "descripcion": textosR.descripcion,
                            "ok": textos.ok,
                            "okr":textosR.ok,
                            "ko": textos.ko,
                            "kor":textosR.ko                           
                            }) 
    } 

    const getTextos = (condicion, replace=true, nombreControl="", valor="") => {
        let data = { descripcion : "", ok : "", ko : "" };
        let descripcionAux = "";
        let okAux = "";
        let koAux = "";
        let clave;

        if( nombreControl != "ok" && nombreControl != "ko" ){

            switch(parseInt(condicion)){
                case 1:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.cero;                                   
                    break;
                case 2:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.nocero;                  
                    break;
                case 3:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.negativo;
                    break;
                case 4:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.positivo;                   
                    break;
                case 5:
                case 14:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.retrocede;                    
                    break;
                case 6:
                case 15:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.avanza;                   
                    break;
                case 7:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.novaria;                   
                    break;
                case 8:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.varia;                   
                    break;
                case 9:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.superior;                   
                    break;
                case 10:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.inferior;                    
                    break;
                case 11:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.dentro;                   
                    break;
                case 12:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.fuera;                    
                    break;
                case 13:
                        clave = confPantalla.argosAlarmas.alarmasmo.padre.textos.nocomunica;                    
                    break;
                default:
                    break;
            }
    
            if( condicion ){
                const { descripcion, ok, ko } = clave;
                // Descripción
                descripcionAux = descripcion.title;

                // OK
                if( ok.title != formularioPrin.ok && formularioPrin.ok != "" && nombreControl != "condicion" ){
                    okAux = formularioPrin.ok;
                }else{
                    okAux = ok.title;
                }
                // KO
                if( ko.title != formularioPrin.ko && formularioPrin.ko != "" && nombreControl != "condicion" ){
                    koAux = formularioPrin.ko;
                }else{
                    koAux = ko.title;
                }
            }else{
                // Descripción
                descripcionAux = "";                      
                // OK
                okAux = "";                                          
                // KO
                koAux = "";
            }
        }else{
            descripcionAux = formularioPrin.descripcion;
            if( nombreControl == "ok" ){
                okAux = valor;
                koAux = formularioPrin.ko
            }
            if( nombreControl == "ko" ){
                okAux = formularioPrin.ok;
                koAux = valor;
            }
        }
        
        data.descripcion = replaceTextosAlarmas(descripcionAux, nombreControl, valor);
        if( replace ){
            data.ok = replaceTextosAlarmas(okAux, nombreControl, valor);
            data.ko = replaceTextosAlarmas(koAux, nombreControl, valor);
        }else{
            data.ok = okAux;
            data.ko = koAux;
        }
        return data;

    }

    const replaceTextosAlarmas = (texto, nombreControl, valor) => {

        // Sensor
        let idSensor;
        if( nombreControl == "sensor" ){
            idSensor = valor;
        }else{
            idSensor = formularioPrin.sensor;
        }
        const opcionSensor = optionsSensor.filter(option => option.value == idSensor);

        // Condición
        let idCondicion;
        let opcionCondicion;
        if( nombreControl == "condicion" ){
            idCondicion = valor;
        }else{
            idCondicion = formularioPrin.condicion;
        }
        opcionCondicion = confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.data.filter(option => option.value == idCondicion);
        if(opcionCondicion.length == 0){
            opcionCondicion = confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.data.filter(option => option.value == idCondicion);
        }
        if(opcionCondicion.length == 0){
            opcionCondicion = confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.data.filter(option => option.value == idCondicion);
        }
        if(opcionCondicion.length == 0){
            opcionCondicion = confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.data.filter(option => option.value == idCondicion);
        }
        
        // Nivel
        let idNivel;
        if( nombreControl == "nivel" ){
            idNivel = valor;
        }else{
            idNivel = formularioPrin.nivel;
        }
        const opcionNivel = confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.data.filter(option => option.value == idNivel);
    
        if( nombreControl == "sensor" ){                        
            texto = valor                        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "condicion" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "tintervalo" || nombreControl == "nintervalo" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(valor)) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%TIM%", valor) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "nivel" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "destinatarios" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%DES%", valor) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "refsupinf" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%REF%", valor) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "rangomin" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%MIN%", valor) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }else if( nombreControl == "rangomax" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = valor                        != "" ? MiReplaceAll(texto, "%MAX%", valor) : texto;
        }else if( nombreControl == "ok" || nombreControl == "ko" || nombreControl == "pad" || nombreControl == "nexo" || nombreControl == "nombre" ){
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%CEN%", opcionSensor[0].label.split("||")[0].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%EQU%", opcionSensor[0].label.split("||")[1].trim()) : texto;
            texto = formularioPrin.sensor        != "" ? MiReplaceAll(texto, "%VAR%", opcionSensor[0].label.split("||")[2].trim()) : texto;
            texto = formularioPrin.condicion     != "" ? MiReplaceAll(texto, "%CON%", opcionCondicion[0].label) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%EST%", getEscalaTemporal(formularioPrin.intervalo)) : texto;
            texto = formularioPrin.intervalo     != "" ? MiReplaceAll(texto, "%TIM%", formularioPrin.intervalo) : texto;
            texto = formularioPrin.nivel         != "" ? MiReplaceAll(texto, "%LVL%", opcionNivel[0].label) : texto;
            texto = formularioPrin.destinatarios != "" ? MiReplaceAll(texto, "%DES%", formularioPrin.destinatarios) : texto;
            texto = formularioPrin.refsupinf     != "" ? MiReplaceAll(texto, "%REF%", formularioPrin.refsupinf) : texto;
            texto = formularioPrin.rangomin      != "" ? MiReplaceAll(texto, "%MIN%", formularioPrin.rangomin) : texto;
            texto = formularioPrin.rangomax      != "" ? MiReplaceAll(texto, "%MAX%", formularioPrin.rangomax) : texto;
        }
    
        return texto;

    }

    const handleInputs = (e) => {            
        updateStateForm({value: e.target.value},{name:e.target.name});        
    }

    const handleInputNumIntervalo = (e) => {
        calculateIntervalo(null, e.target.value, e.target.name);
    }

    const handleSelectTiempoIntervalo = (selectedItem, nameOfComponent) => {
        let valor;

        if( selectedItem ){
            valor = selectedItem.value;
        }else{
            valor = "";
        }
        calculateIntervalo(valor, null, nameOfComponent.name);
    }

    const calculateIntervalo = (tintervalo, nintervalo, nombreControl) => {
        
        let total, condicion, cantidad;

        if( tintervalo === null ){
            condicion = formularioPrin.tintervalo;
            cantidad = nintervalo;
        }
        if( nintervalo === null ){
            condicion = tintervalo;
            cantidad = formularioPrin.nintervalo;
        }    

        switch(condicion){
            case 0:
            case "":
                // Vacio
                total = cantidad;
                break;
            case 1:
            case "1":
                // Horas
                total = cantidad*60;
                break;
            case 2:
            case "2":
                // Días
                total = cantidad*60*24;
                break;
            case 3:
            case "3":
                // Semanas
                total = cantidad*60*24*7;
                break;
            case 4:
            case "4":
                // Meses
                total = cantidad*60*24*30;
                break;
        }
        let textos, textosR;
            textos = getTextos(formularioPrin.condicion, false, nombreControl, total);
            textosR = getTextos(formularioPrin.condicion, true, nombreControl, total);
            saveFormularioPrin({...formularioPrin, 
                                "descripcion": textosR.descripcion,
                                "ok": textos.ok,
                                "okr":textosR.ok,
                                "ko": textos.ko,
                                "kor":textosR.ko,
                                "nintervalo" : cantidad,
                                "tintervalo" : condicion,
                                "intervalo" : total
                                })           
    }


 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={confPantalla.argosAlarmas.alarmasmo.section.titulo} icono={confPantalla.argosAlarmas.alarmasmo.section.icono} col={ "12" } idPrin={ formularioPrin.id } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.argosAlarmas.alarmasmo.padre.btnform.new }
                                                    btnSave={ confPantalla.argosAlarmas.alarmasmo.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.argosAlarmas.alarmasmo.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.argosAlarmas.alarmasmo.padre.btnform.delete }
                                                    btnClone={ confPantalla.argosAlarmas.alarmasmo.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.argosAlarmas.alarmasmo.padre.urls, confPantalla.argosAlarmas.alarmasmo.padre.modelform, confPantalla.argosAlarmas.alarmasmo.padre.tabla, confPantalla.argosAlarmas.alarmasmo.hijo.modelform, confPantalla.argosAlarmas.alarmasmo.title) }
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.argosAlarmas.alarmasmo.padre.modelform, true, confPantalla.argosAlarmas.alarmasmo.padre.tabla) }  
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.argosAlarmas.alarmasmo.padre.urls, confPantalla.argosAlarmas.alarmasmo.padre.modelform, confPantalla.argosAlarmas.alarmasmo.padre.tabla, confPantalla.argosAlarmas.alarmasmo.title) }
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.argosAlarmas.alarmasmo.padre.urls, confPantalla.argosAlarmas.alarmasmo.hijo.urls, confPantalla.argosAlarmas.alarmasmo.padreshijos, confPantalla.argosAlarmas.alarmasmo.hijo.modelform, confPantalla.argosAlarmas.alarmasmo.padre.tabla, confPantalla.argosAlarmas.alarmasmo.title) }  
                                        >                                        

                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { "" }
                                                            val=         { formularioPrin.pad  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.id }    
                                                            name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.name }   
                                                            label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.label }    
                                                            placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.placeholder }
                                                            col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.col } 
                                                            isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.isclearable }
                                                            isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.issearchable }
                                                            dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.datatype }
                                                            bbdd=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.bbdd }   
                                                            data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.data }
                                                            required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.required } 
                                                            disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.disabled }
                                                            readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.readonly }
                                                            disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.pad.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { updateStateForm }
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { "" }
                                                            val=         { formularioPrin.nexo  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.id }    
                                                            name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.name }   
                                                            label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.label }    
                                                            placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.placeholder }
                                                            col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.col } 
                                                            isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.isclearable }
                                                            isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.issearchable }
                                                            dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.datatype }
                                                            bbdd=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.bbdd }   
                                                            data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.data }
                                                            required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.required } 
                                                            disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.disabled }
                                                            readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.readonly }
                                                            disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.nexo.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { updateStateForm }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nombre }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.id }   
                                                            type=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.type }  
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.name }   
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.required }
                                                            readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.nombre.disabled }
                                                            change=     { handleInputs }
                                                        />  
                                                    </FormRow>
                                                    <FormRow clases="">
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.descripcion}
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.id }   
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.name }
                                                            rows=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.rows }      
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.required }
                                                            readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.descripcion.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        />
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <MiSelect  
                                                            estadoForm=  { "" }
                                                            val=         { formularioPrin.plantilla  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.id }    
                                                            name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.name }   
                                                            label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.label }    
                                                            placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.placeholder }
                                                            col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.col } 
                                                            isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.isclearable }
                                                            isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.issearchable }
                                                            dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.datatype }
                                                            data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.data }
                                                            required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.required } 
                                                            disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.disabled }
                                                            readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.readonly }
                                                            disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.disablethisid}
                                                            disableids={confPantalla.argosAlarmas.alarmasmo.padre.controles.plantilla.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { updateStateForm }
                                                        />  
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <MiSelect  
                                                            estadoForm=  { "" }
                                                            val=         { formularioPrin.sensor  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.id }    
                                                            name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.name }   
                                                            label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.label }    
                                                            placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.placeholder }
                                                            col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.col } 
                                                            isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.isclearable }
                                                            isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.issearchable }
                                                            dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.datatype }
                                                            bbdd=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.bbdd }   
                                                            data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.data }
                                                            required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.required } 
                                                            disabled=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.disabled }
                                                            readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.readonly }
                                                            disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.disablethisid }
                                                            disableids=   {confPantalla.argosAlarmas.alarmasmo.padre.controles.sensor.disableids }
                                                            formulario= { formularioPrin }   
                                                            change= { updateStateForm }
                                                            saveDataToFather = { saveOptionsSensor }
                                                        />
                                                        {
                                                            formularioPrin.plantilla == 1 
                                                            ?
                                                                <Fragment>
                                                                    <MiSelect  
                                                                        estadoForm=  { "" }
                                                                        val=         { formularioPrin.condicion  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.id }    
                                                                        name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.name }   
                                                                        label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.label }    
                                                                        placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.placeholder }
                                                                        col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.col } 
                                                                        isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.isclearable }
                                                                        isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.issearchable }
                                                                        dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.datatype }
                                                                        data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.data }
                                                                        required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.required } 
                                                                        disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.disabled }
                                                                        readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.readonly }
                                                                        disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.disablethisid}
                                                                        disableids={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp1.disableids}
                                                                        formulario= { formularioPrin }   
                                                                        change= { updateStateForm }
                                                                    />  
                                                                </Fragment>
                                                            :
                                                                formularioPrin.plantilla == 2
                                                            ?
                                                                <Fragment>                                                                                                                               
                                                                    <MiSelect  
                                                                        estadoForm=  { "" }
                                                                        val=         { formularioPrin.condicion  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.id }    
                                                                        name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.name }   
                                                                        label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.label }    
                                                                        placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.placeholder }
                                                                        col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.col } 
                                                                        isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.isclearable }
                                                                        isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.issearchable }
                                                                        dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.datatype }
                                                                        data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.data }
                                                                        required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.required } 
                                                                        disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.disabled }
                                                                        readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.readonly }
                                                                        disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.disablethisid}
                                                                        disableids={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp2.disableids}
                                                                        formulario= { formularioPrin }   
                                                                        change= { updateStateForm }
                                                                    />   
                                                                </Fragment>
                                                            :
                                                                formularioPrin.plantilla == 3
                                                                ?
                                                                <Fragment>                                                                                                                                
                                                                    <MiSelect  
                                                                        estadoForm=  { "" }
                                                                        val=         { formularioPrin.condicion  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.id }    
                                                                        name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.name }   
                                                                        label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.label }    
                                                                        placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.placeholder }
                                                                        col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.col } 
                                                                        isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.isclearable }
                                                                        isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.issearchable }
                                                                        dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.datatype }
                                                                        data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.data }
                                                                        required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.required } 
                                                                        disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.disabled }
                                                                        readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.readonly }
                                                                        disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.disablethisid}
                                                                        disableids={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp3.disableids}
                                                                        formulario= { formularioPrin }   
                                                                        change= { updateStateForm }
                                                                    /> 
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.refsupinf }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.id }   
                                                                        type=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.type }  
                                                                        name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.name }   
                                                                        label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.label }                                                       
                                                                        placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.placeholder }    
                                                                        col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.col } 
                                                                        required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.required }
                                                                        readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.readonly }                                                
                                                                        disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.refsupinf.disabled }
                                                                        change=     { handleInputs }
                                                                    />   
                                                                </Fragment>
                                                                :
                                                                formularioPrin.plantilla == 4
                                                                ?
                                                                <Fragment>                                                                    
                                                                    <MiSelect  
                                                                        estadoForm=  { "" }
                                                                        val=         { formularioPrin.condicion  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.id }    
                                                                        name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.name }   
                                                                        label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.label }    
                                                                        placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.placeholder }
                                                                        col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.col } 
                                                                        isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.isclearable }
                                                                        isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.issearchable }
                                                                        dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.datatype }
                                                                        data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.data }
                                                                        required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.required } 
                                                                        disabled=  { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.disabled }
                                                                        readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.readonly }
                                                                        disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.disablethisid}
                                                                        disableids={confPantalla.argosAlarmas.alarmasmo.padre.controles.condicionp4.disableids}
                                                                        formulario= { formularioPrin }   
                                                                        change= { updateStateForm }
                                                                    /> 
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.rangomin }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.id }   
                                                                        type=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.type }  
                                                                        name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.name }   
                                                                        label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.label }                                                       
                                                                        placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.placeholder }    
                                                                        col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.col } 
                                                                        required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.required }
                                                                        readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.readonly }                                                
                                                                        disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomin.disabled }
                                                                        change=     { handleInputs }
                                                                    />
                                                                     <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.rangomax }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.id }   
                                                                        type=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.type }  
                                                                        name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.name }   
                                                                        label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.label }                                                       
                                                                        placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.placeholder }    
                                                                        col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.col } 
                                                                        required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.required }
                                                                        readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.readonly }                                                
                                                                        disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.rangomax.disabled }
                                                                        change=     { handleInputs }
                                                                    />   
                                                                </Fragment>
                                                                :
                                                                null
                                                        }
                                                    </FormRow>
                                                    <FormRow>                                                        
                                                        <FormInput                                                        
                                                            estadoForm= { "" }
                                                            value=      { formularioPrin.nintervalo }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { "nintervalo" }   
                                                            type=       { "number" }  
                                                            name=       { "nintervalo" }   
                                                            label=      { "Cantidad" }                                                       
                                                            placeholder={ "" }    
                                                            col=        { 3 } 
                                                            required=   { true }
                                                            readonly=   { "" }                                                
                                                            disabled=   { "" }
                                                            change=     { e => handleInputNumIntervalo(e) }
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { "" }
                                                            val=         { formularioPrin.tintervalo }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { "tintervalo" }    
                                                            name=        { "tintervalo" }   
                                                            label=       { "Frecuencia (%EST%)" }    
                                                            placeholder= { "Frecuencia" }
                                                            col=         { 6 } 
                                                            isClearable= { false }
                                                            isSearchable={ true }
                                                            dataType=    { "codigo" }
                                                            data=        { [
                                                                            {value: 0,label: "Minutos"},            
                                                                            {value: 1,label: "Horas"},
                                                                            {value: 2,label: "Días"},
                                                                            {value: 3,label: "Semanas"},
                                                                            {value: 4,label: "Meses"}
                                                                        ] }
                                                            required=    { false } 
                                                            disabled=  { false }
                                                            readOnly =   { false }
                                                            disablethisid={null}
                                                            disableids={null}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectTiempoIntervalo }
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.intervalo }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.id }   
                                                            type=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.type }  
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.name }   
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.required }
                                                            readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.intervalo.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        />
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <MiSelect  
                                                            estadoForm=  { "" }
                                                            val=         { formularioPrin.nivel  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.id  }    
                                                            name=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.name }   
                                                            label=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.label }    
                                                            placeholder= { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.placeholder }
                                                            col=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.col } 
                                                            isClearable= { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.isclearable }
                                                            isSearchable={ confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.issearchable }
                                                            dataType=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.datatype }
                                                            data=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.data }
                                                            required=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.required } 
                                                            disabled=    { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.disabled }
                                                            readOnly =   { confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.readonly }
                                                            disablethisid={confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.disablethisid}
                                                            disableids=  {confPantalla.argosAlarmas.alarmasmo.padre.controles.nivel.disableids }
                                                            formulario= { formularioPrin }   
                                                            change= { updateStateForm }
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.destinatarios }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.id }   
                                                            type=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.type }  
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.name }   
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.required }
                                                            readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.destinatarios.disabled }
                                                            change=     { handleInputs }
                                                        />  
                                                    </FormRow>
                                                    <FormRow>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.ok }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.id }   
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.name }
                                                            rows=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.rows }      
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.required }
                                                            readonly=   { formularioPrin.condicion == "" ? true : confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.ok.disabled }
                                                            change=     { e => handleInputOkKo(e, formularioPrin, saveFormularioPrin) }
                                                        />
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.okr }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.id }   
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.name }
                                                            rows=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.rows }      
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.required }
                                                            readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.okr.disabled }
                                                            change=     { e => handleInputOkKo(e, formularioPrin, saveFormularioPrin) }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.ko }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.id }   
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.name }
                                                            rows=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.rows }      
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.required }
                                                            readonly=   { formularioPrin.condicion == "" ? true : confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.ko.disabled }
                                                            change=     { e => handleInputOkKo(e, formularioPrin, saveFormularioPrin) }
                                                        />
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.kor }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.id }   
                                                            name=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.name }
                                                            rows=       { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.rows }      
                                                            label=      { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.label }                                                       
                                                            placeholder={ confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.placeholder }    
                                                            col=        { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.col } 
                                                            required=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.required }
                                                            readonly=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.readonly }                                                
                                                            disabled=   { confPantalla.argosAlarmas.alarmasmo.padre.controles.kor.disabled }
                                                            change=     { e => handleInputOkKo(e, formularioPrin, saveFormularioPrin) }
                                                        />
                                                    </FormRow>
                                        </Formulario>
                                        <MiDataTable
                                            formulario={formularioPrin} 
                                            estadoForm= { estadoFormPrin }
                                            id={"alarmas"}
                                            columnas={confPantalla.argosAlarmas.alarmasmo.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.argosAlarmas.alarmasmo.padre.datatable.buttons}
                                            ordering={confPantalla.argosAlarmas.alarmasmo.padre.datatable.ordering}
                                            order={confPantalla.argosAlarmas.alarmasmo.padre.datatable.order}
                                            searching={confPantalla.argosAlarmas.alarmasmo.padre.datatable.searching}
                                            paging={confPantalla.argosAlarmas.alarmasmo.padre.datatable.paging}
                                            select={confPantalla.argosAlarmas.alarmasmo.padre.datatable.select}
                                            pagelength={confPantalla.argosAlarmas.alarmasmo.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.argosAlarmas.alarmasmo.padre.urls, confPantalla.argosAlarmas.alarmasmo.hijo.urls, confPantalla.argosAlarmas.alarmasmo.padreshijos, confPantalla.argosAlarmas.alarmasmo.hijo.modelform, confPantalla.argosAlarmas.alarmasmo.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.argosAlarmas.alarmasmo.padre.urls, confPantalla.argosAlarmas.alarmasmo.padre.modelform, confPantalla.argosAlarmas.alarmasmo.padre.tabla, confPantalla.argosAlarmas.alarmasmo.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.argosAlarmas.alarmasmo.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Alarmasmo
import React, { useContext, useState, useEffect, Fragment } from 'react';
import Cookies from 'universal-cookie';
import Select from 'react-select';
import MiSelect from '../../formulario/MiSelect';
import axios from 'axios';
import { Alert } from '../../ui/Alert';
import { alertService } from '../../../services/alertservice';
import { NavLink } from 'react-router-dom';

import { getApiUrl } from '../../../helpers/helperApp';


import { LoginContext } from '../../../hooks/context/LoginContext';

import Menusupitem from './Menusupitem';
import Notifications from '../../ui/Notifications';


function Menusup(props){

    const { cerrarSesion, confApp, apiKey, centroActivo, saveCentroActivo } = useContext(LoginContext);    

    const [loadedConf, saveLoadedConf] = useState(false);
    const [loading, saveLoading] = useState(false);

    const cookies = new Cookies();

    const customStyles = {
      control: (base, state) => ({
          ...base,
          borderColor:'#ced4da',
          borderColor: state.isFocused ? '#80bdff': '#ced4da',
          outline: 0,
          boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
          // background: readOnly === "readonly" || loading || estadoForm === "readonly" ? "#e9ecef" : "white",
          color: "#495057",
          borderTop: "none",
          borderLeft : "none",
          borderRight : "none",
          borderRadius: "0px",
          boxShadow: state.isHover ? "none" : "none"
        }),
        option: (provided, state) => ({
          ...provided,
          color: "#212529",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#495057",
        }),
        menuPortal: (provided) => ({
          ...provided,            
          zIndex: 9999
        }) 
  }

    useEffect(() => {
      
      loadCentro();
      saveLoadedConf(true);
      
  },[loadedConf])

    const handleCerrarSesion = (props) => {
        cerrarSesion(props);       
    }

    const handleChangeLang = async (selectedItem, nameOfComponent) => {

      const data = new FormData();
      data.append("validacion", apiKey());
      data.append("lang", selectedItem.value)

      const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/updateLang', data);
      if( typeof respuesta.data == "number" ){
        // Recargamos la página. Le pasamos el parámetro a true para recargue desde el servidor y no desde la caché.
        window.location.reload(true);
      }else if(typeof respuesta.data == "string"){
        // Mostramos el error
        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
      }
    }

    const loadCentro = async () => {

      const data = new FormData();
      data.append("validacion", apiKey());
      data.append("bbdd", "argos_mo");      

      const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/getActiveCenter', data);
      
      if(respuesta.data.error){       
        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
      }else{
        saveCentroActivo(respuesta.data);
      }

    }

    const handleChangeCentro = async (selectedItem, nameOfComponent) => {

      saveLoading(true);
      let idCentro;
      if( selectedItem ){
          saveCentroActivo(selectedItem.value);
          idCentro = selectedItem.value;  
      }else{
          // saveCentroActivo(0);
          // idCentro = 0;
          saveCentroActivo("");
          idCentro = "";
      }
      const data = new FormData();
      data.append("validacion", apiKey());
      data.append("idcen", idCentro);
      data.append("bbdd", "argos_mo");

      const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/updateActiveCenter', data);
      saveLoading(false);
      if( respuesta.data === null ){
        // Recargamos la página. Le pasamos el parámetro a true para recargue desde el servidor y no desde la caché.
        window.location.reload(true);
      }else if(typeof respuesta.data == "string"){
        // Mostramos el error
        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
      }
    }

    return(
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link sidebar-toogle-btn" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
         
        </ul>

        <ul className="navbar-nav ml-auto">
          {
            loadedConf != undefined
            ?
              <Fragment>
                <Alert />
                {
                  confApp != undefined
                  ?
                    <Notifications  confApp={confApp} />
                  :
                    null
                }
                {
                    confApp != undefined && (cookies.get('nivel') == "1" || cookies.get('nivel') == "2" || cookies.get('nivel') == "3" || cookies.get('nivel') == "4")
                    ?
                      <li className="nav-item" style={{minWidth:"300px", paddingTop:"2px"}} >
                        <MiSelect  
                            estadoForm=  { "" }
                            val=         { centroActivo }   
                            loading=     { loading ? true : false }
                            id=          { confApp.argosAlarmas.menusup.centro.select.id }    
                            name=        { confApp.argosAlarmas.menusup.centro.select.name }   
                            label=       { confApp.argosAlarmas.menusup.centro.select.label }    
                            placeholder= { confApp.argosAlarmas.menusup.centro.select.placeholder }
                            col=         { confApp.argosAlarmas.menusup.centro.select.col } 
                            isClearable= { confApp.argosAlarmas.menusup.centro.select.isclearable }
                            isSearchable={ confApp.argosAlarmas.menusup.centro.select.issearchable }
                            dataType=    { confApp.argosAlarmas.menusup.centro.select.datatype }
                            data=        { confApp.argosAlarmas.menusup.centro.select.data }
                            bbdd=        { confApp.argosAlarmas.menusup.centro.select.bbdd }
                            required=    { confApp.argosAlarmas.menusup.centro.select.required } 
                            disabled=    { confApp.argosAlarmas.menusup.centro.select.disabled }
                            readOnly =   { confApp.argosAlarmas.menusup.centro.select.readonly }
                            disablethisid={confApp.argosAlarmas.menusup.centro.select.disablethisid}
                            extradata=    {confApp.argosAlarmas.menusup.centro.select.extradata}
                            formulario= { {id:0} }   
                            change= { handleChangeCentro }
                        />                                                
                      </li>                      
                    :
                      null
                }
                {
                    confApp != undefined
                    ?
                      <li className="nav-item" style={{width:"75px"}}>
                        <Select
                          value={confApp.menuidioma.length > 0 ? confApp.menuidioma.filter(option => option.value == cookies.get('idioma')) : null}
                          onChange={handleChangeLang}
                          options={
                            confApp.menuidioma.map((idioma)=>{
                              return { value: idioma.value, label: idioma.label }
                            })
                          }
                          styles={customStyles}
                        />
                      </li>                      
                    :
                      null
                }
                <li className="nav-item">
                  <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i className="fas fa-expand-arrows-alt"></i>
                  </a>
                </li>
                {
                    confApp != undefined
                    ?
                      <li className="nav-item">
                        <a onClick={ e => { handleCerrarSesion(props) } }  className="nav-link" role="button">
                          <i className="fal fa-sign-out-alt"></i>{confApp.config.trad.menusup.cerrarsesion.title}
                        </a>
                      </li>
                    :
                      null
                }



              </Fragment>
            :
            null
          }
          

                    
        </ul>
      </nav>
    )
}

export default Menusup;
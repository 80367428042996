import { React, useEffect, useState } from 'react';

import axios from 'axios';

import { LoginProvider } from '../hooks/context/LoginContext';

import SimpleReactLightbox from 'simple-react-lightbox';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { getApiUrl } from '../helpers/helperApp';

import Login from '../pages/login/Login';
import Registro from '../pages/login/Registro';
import Inicio from '../pages/inicio/Inicio';
import ForgotPass from '../pages/login/ForgotPass';
import ChangePass from '../pages/login/ChangePass';
import Profile from '../pages/profile/Profile';
import NotFound from '../componentes/NotFound';
import VisorInformes from '../pages/visorinformes/VisorInformes';
import Alarmas from '../pages/alarmasmo/Alarmasmo';

function App() {

      const [recoverPass, saveRecoverPass] = useState();
      const [register, saveRegister] = useState();
  
      useEffect( () => {

            // ***** Obtener las validaciones y configuraciones iniciales *****
            const getValidaciones = async () => {
                const url = `${getApiUrl(window.location.origin)}/login/getValidaciones`;
                const res = await axios.post(url);      
                res.data.map( validacion => {
                      if( validacion.cod === "login.recoverpass.allow" ){
                        saveRecoverPass(validacion.val);
                      }
                      if( validacion.cod === "login.register.allow" ){
                        saveRegister(validacion.val);
                      }
                } )
            }

            getValidaciones();
      },[])

      return (
        
        <SimpleReactLightbox>
          <LoginProvider>
                {/* <BrowserRouter forceRefresh={true}> */}
                <BrowserRouter>
                  <Switch>
                    {/*****  Login ******/}
                    <Route exact path="/" component={ Login } />
                    <Route exact path="/:paramId/:paramEmail/:paramNombre/:paramSesion/:paramNivel/:paramEstado/:paramIdioma/:paramImgUser/:paramIdAge/:paramUrl/:paramOrigenTabMA" component={ Login }/>
                    {
                      register === "1"
                      ?
                        <Route exact path="/registro" component={ Registro } />
                      :
                        <Route exact path="/registro" component={ Login } />

                    }
                    {
                      recoverPass === "1"
                      ?
                        <Route exact path="/forgotpass" component={ ForgotPass } />
                      :
                        <Route exact path="/forgotpass" component={ Login } />
                    }
                    {
                      recoverPass === "1"
                      ?
                        <Route exact path="/changepass/:info" component={ ChangePass } />
                      :
                        <Route exact path="/changepass/:info" component={ Login } />
                    }                 
                    
                    {/*****  Inicio ******/}
                    <Route exact path="/inicio" component={ Inicio } />
                    <Route exact path="/profile" component={ Profile } />
                    <Route exact path="/profile/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/profile', state: {...match.params}}} />;
                                      }} 
                          />   

                   
                    <Route exact path="/visorinformes" component={ VisorInformes } />                          
                    <Route exact path="/alarmasmo" component={ Alarmas } />
                    <Route exact path="/alarmasmo/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/alarmasmo', state: {...match.params}}} />;
                                      }} 
                          />
                    {/*****  Página no encontrada ******/}
                    <Route component={NotFound} />
                  </Switch>
                </BrowserRouter>
          </LoginProvider>
        </SimpleReactLightbox>

      );
}

export default App;

import React, { useState, useEffect, useContext, Fragment } from 'react';
import Cookies from 'universal-cookie';
import { Link, NavLink } from 'react-router-dom';
import { LoginContext } from '../../../hooks/context/LoginContext';
import { loadTree } from '../../../helpers/MenuTreeHelper';
import { getIndexUrl } from '../../../helpers/helperApp';

import MenuSuperAdmin from './MenuSuperAdmin';
import MenuAdmin from './MenuAdmin';
import MenuEnc from './MenuEnc';
import MenuTrab from './MenuTrab';
import MenuEncCheckList from './checklist/MenuEncCheckList';
import MenuTrabCheckList from './checklist/MenuTrabCheckList';
import MenuCustom from './MenuCustom';

import { getApiUrl } from '../../../helpers/helperApp';



function Sidebar(props){
 
    const { logo, sidebarLogo, activeMenuColor, sidebarColor, confApp, menuMode, customMenu } = useContext(LoginContext); 

    const [usuario, saveUsuario] = useState("");
    const [miNivel, saveMiNivel] = useState();
    const [miId, saveMiId] = useState();
    const [imgUser, saveImgUser] = useState(null);

    useEffect(() => {
      
        const cookies = new Cookies();

        loadTree();
        
        // Obtenemos el nombre de usuario de las cookies
        if( cookies.get('nombre') ){
          saveUsuario(cookies.get('nombre'));
        }
        // Obtenemos el nivel de usuario para filtrar el menú en función de su nivel
        if( cookies.get('nivel') ){
          saveMiNivel(cookies.get('nivel'));
        }
        // Obtenemos el id de usuario
        if( cookies.get('id') ){
          saveMiId(cookies.get('id'));
        }
        // Obtenemos la imagen de perfil del usuario
        if( cookies.get('imguser') != "null" ){
          saveImgUser(cookies.get('imguser'));
        }
        
      },[])

      let menu = null;
      menu = <MenuSuperAdmin 
                confApp = { confApp }
                miNivel = { miNivel }
                activeMenuColor = { activeMenuColor }
              />
      
     
      
    return(
        <Fragment>
        {
          confApp != undefined
          ?
            <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ backgroundColor: sidebarColor }}>
              <Link to={getIndexUrl(menuMode)} className="brand-link" style={{minHeight: "57px"}}>
                <img src={sidebarLogo} alt="" className="brand-image img-circle elevation-3" style={{opacity: .8, backgroundColor: "white"}} />
                <span className="brand-text font-weight-light">{confApp.config.trad.alarmas.sidebar.title}</span>
              </Link>
          
              <div className="sidebar sidebar os-host os-theme-light os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition os-host-scrollbar-vertical-hidden">

              <div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{left: "0px", right: "auto"}}></div></div>
                <div className="os-size-auto-observer observed" style={{height: "calc(100% + 1px)", float: "left"}}><div className="os-resize-observer"></div></div>
                <div className="os-content-glue" style={{margin: "0px -8px", width: "249px", height: "879px"}}></div>
                <div className="os-padding">
                  <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{overflowY: "scroll"}} >
                    <div className="os-content" style={{padding: "0px 8px", height: "100%", width: "100%"}}>

                    

                                      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                                          <div className="image">
                                            {
                                                imgUser != null
                                              ?
                                                <img src={getApiUrl(window.location.origin)+imgUser} className="img-circle elevation-2" alt="User Image" />
                                              :
                                                <img src={getApiUrl(window.location.origin)+"/assets/web/avatar.png"} className="img-circle elevation-2" alt="User Image" />
                                            }
                                          </div>
                                        <div className="info">
                                          <Link to={"/profile/"+miId} className="d-block">
                                            {confApp.profile.sidebarmenu.titulo} {usuario}
                                            <i className={confApp.profile.sidebarmenu.icono} style={{marginLeft:5}} />
                                          </Link>
                                        </div>
                                      </div>
                                      {menu}
                    </div>
                  </div>  
                </div>                                              
              </div>
            </aside>
          :
          null
        }
        </Fragment>
       

    )
}

export default Sidebar;
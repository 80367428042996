import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LoginContext } from '../../hooks/context/LoginContext';

import { Alert } from '../../componentes/ui/Alert';

import 'bootstrap/dist/css/bootstrap.min.css';

import LoadingPage from '../../componentes/ui/LoadingPage';

import Spinner from 'react-bootstrap/Spinner';
import { Fragment } from 'react';

import { getIndexUrl } from '../../helpers/helperApp';
import GoogleLogin from 'react-google-login';




function Login(props){

    // ***** State para comprobar si está cargada la configuración general de aplicación  *****
    const [ isConfApp, saveIsConfApp ] = useState(true)

    const { form, saveForm, cookies, getValidaciones, inicioValidaciones, iniciarSesionExt, iniciarSesion, validaPassword, loginGoogle, logo, recuperapass, registrarse, backgroundImg, darkLightForm, loadLogin, saveLoadLogin, getConfApp, confApp, noConfText, menuMode, googleClientId, validacionesCargadas, loginTxtColor, apiKey  } = useContext(LoginContext);   

    const { paramId, paramEmail, paramNombre, paramSesion, paramNivel, paramEstado, paramIdioma, paramImgUser, paramIdAge, paramUrl, paramOrigenTabMA } = props.match.params;

    // ***** Funciones que se ejecutan al terminar  de cargar el componente *****
    useEffect(() => {
            
            if( paramId !== undefined && paramEmail !== undefined && paramNombre !== undefined && paramSesion !== undefined && paramNivel !== undefined && paramEstado !== undefined && paramIdioma !== undefined && paramImgUser !== undefined && paramIdAge !== undefined && paramUrl !== undefined && paramUrl !== undefined && paramOrigenTabMA !== undefined ){
                const loadConf = async () => {
                    const res = await getValidaciones();
                    await inicioValidaciones(res); 
                    const conf = await getConfApp();
                        if( conf.res.toLowerCase() === "ko".toLocaleLowerCase() ){
                            saveIsConfApp(false); 
                            return;                      
                        }
                        handleIniciarSesionExt();
                }                
                loadConf();

            }else{
                if( cookies.get('id') ){
                    props.history.push(getIndexUrl(menuMode));
                }else{
                    const validaciones = async () => {
                        const res = await getValidaciones();
                        await inicioValidaciones(res); 
                                                                      
                        const conf = await getConfApp();
                        if( conf.res.toLowerCase() === "ko".toLocaleLowerCase() ){
                            saveIsConfApp(false);                        
                        }
                    }

                    validaciones();
                }
            }

    }, [])


    //***** Login externo a traves de una url ******
    const handleIniciarSesionExt = () => {

        saveLoadLogin(true);
        let iniciar = props.match.params;
        iniciar.location = { pathname : "/"};
        iniciarSesionExt(props.match.params);

        //Redireccionamos al destino recibido en los parámetros
        props.history.push(atob(paramUrl))
    }


    // ***** Lama a la api para comprobar username y password, almacena en las cookies la información del usuario y redirige hacia el inicio *****
    const handleIniciarSesion = async (e) =>{

        saveLoadLogin(true);
        let iniciar = await iniciarSesion(e);

        if(iniciar){
            props.history.push(getIndexUrl(menuMode));
        }else{
            return null;
        }

    }


    // ***** Llama la función de validación del password y almacena en el state del form los valores de username y password *****
    const handleChange = e => {
        if( e.target.name === "password" ){
            validaPassword(e.target.value);
        }

        const { name, value } = e.target;
        saveForm({
            ...form,
            [name]: value
        })
    }

    const responseGoogleOk = async googleData => {                

        saveLoadLogin(true);
        let iniciar = await iniciarSesion(null, googleData.profileObj.email);

        if(iniciar){
            props.history.push(getIndexUrl(menuMode));
        }else{
            return null;
        }
    }

    const responseGoogleKo = async googleData => {        
        console.log(googleData);        
    }

    return(
        <Fragment>
                    {
                    confApp !== undefined && validacionesCargadas
                    ?
                        <Fragment>
                            <div className="hold-transition login-page" style={{backgroundImage:`url("${backgroundImg}")`,backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                                <div className="login-box ">
                                    <Alert sinClases={true} />
                                    <div className={ backgroundImg ? `card card-outline transparent-${darkLightForm}` : "card card-outline"}>
                                            <div className="text-center">
                                                <div className="h1"><img className="logo" style={{width: "300px"}} src={logo} /></div>
                                            </div>
                                            <div className="card-body">
                                    {
                                        isConfApp && confApp !== "ko"
                                        ?
                                                <div>
                                                    <h4 style={{ color: loginTxtColor }} className="login-box-msg">{confApp.config.trad.login.login.header.title}</h4>
                                                    <form onSubmit={ handleIniciarSesion }>
                                                        <div className="input-group mb-3">
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                placeholder={confApp.config.trad.login.login.inputuser.placeholder} 
                                                                autoComplete="off"                                
                                                                name="username" 
                                                                onChange={ handleChange }
                                                                required
                                                            />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text">
                                                                    <span className="fas fa-envelope"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <input 
                                                                type="password" 
                                                                className="form-control" 
                                                                placeholder={confApp.config.trad.login.login.inputpass.placeholder} 
                                                                autoComplete="off"
                                                                name="password" 
                                                                onChange={ handleChange }
                                                                required
                                                            />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text">
                                                                    <span className="fas fa-lock"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="input-group mb-3">
                                                            <div className="validacionPass"> 
                                                                <span className={ minMayus["clase"] }  > { minMayus["cant"] } Mayus <i className={ minMayus["icono"] } ></i></span>
                                                                <span className={ minMinus["clase"] }  > { minMinus["cant"] } Minus <i className={ minMinus["icono"] } ></i></span>
                                                                <span className={ minNum["clase"] }    > { minNum["cant"] }    Num  <i className={ minNum["icono"] } ></i></span>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-primary btn-block mr-2">{confApp.config.trad.login.login.btnsubmit.title} 
                                                                {   
                                                                    loadLogin
                                                                    ? 
                                                                    <Spinner as="span" style={{marginLeft: "10px"}} animation="border" size="sm" />
                                                                    :
                                                                    null
                                                                }      
                                                                </button>
                                                            </div>
                                                        </div>                          
                                                    </form>
                                                    {
                                                        loginGoogle === "1" 
                                                        ?
                                                            <div className="social-auth-links text-center mt-2 mb-3">                                                                
                                                                <GoogleLogin
                                                                    clientId={googleClientId}
                                                                    buttonText={confApp.config.trad.login.login.btngoogle.title} 
                                                                    onSuccess={responseGoogleOk}
                                                                    onFailure={responseGoogleKo}                                                                   
                                                                    className='btn btn-block btn-danger text-center btnLoginGoogle'
                                                                    icon={ false }
                                                                />
                                                            </div>
                                                        :
                                                            null                        
                                                    }
                                                    <div>
                                                        {
                                                            recuperapass === "1" 
                                                            ?                                
                                                            <Link to="/forgotpass" style={{ color: loginTxtColor }} className="float-left">{confApp.config.trad.login.login.lostpass.title} </Link> 
                                                            :
                                                            null                            
                                                        }   
                                                        {
                                                            registrarse === "1" 
                                                            ?            
                                                                <Link to="/registro" style={{ color: loginTxtColor }} className="float-right">{confApp.config.trad.login.login.register.title}</Link>                                                        
                                                            :
                                                            null                            
                                                        }
                                                    </div> 
                                                </div>
                                        :
                                        <div className="alert alert-danger text-center" role="alert">
                                            {noConfText}
                                        </div>

                                    }
                                    </div>
                                </div>                
                                </div>
                        </div>
                        </Fragment>
                    :
                        <LoadingPage />
                }
        </Fragment>
    );
}

export default Login
import React from 'react';

function ButtonOutline({clases, color, type, icono, id, accion, texto, estadoForm, loading, disabled}){
    
    return(
        <button 
            id={id} 
            className={ estadoForm === "readonly" || estadoForm === "disabled" ? ` noDisplay btn btn-flat mr-1 btn-outline-${color} ${clases}` :  `btn btn-flat mr-1 btn-outline-${color} ${clases}` } 
            type={type} 
            onClick={accion !== null ? ()=> accion(): null  } 
            disabled={ loading ? true : disabled } 
        >
            { icono !== "" 
                ?
                <i className={ icono }></i>
                :
                null
            }
            {texto}
        </button>
    )
}

export default ButtonOutline;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';
import { HashLink as Link } from 'react-router-hash-link';
import { getApiUrl } from '../../../helpers/helperApp';
import Cookies from 'universal-cookie';


const url = window.location;

function MenuCustom({ miNivel, activeMenuColor, confApp, propiedades }){

    
    const cookies = new Cookies();

    const [ actual, saveActual ] = useState(0);
    const [ open, saveOpen ] = useState(false); 
    const [ menu, saveMenu ] = useState(null);

    useEffect(() => {
        loadTree();

        readMenu();

    },[])

    const readMenu = async () => {     

        const nivelUsu =  cookies.get('nivel');
        const idiomaUsu =  cookies.get('idioma')

        const res = await axios(url.origin+`/assets/_menu/_${nivelUsu}_${idiomaUsu}.json`);
        saveMenu(res.data.menu);
        
        let indiceActual;

        res.data.menu.map((menuItem, index, menu) => {
            if( menuItem.sub === "" ){
                if(Object.keys(propiedades).length > 0 && propiedades.props.location.state ){
                    if( menuItem.href == url.pathname+"/"+propiedades.props.location.state.filter ){
                        indiceActual = index;
                    }
                }else{
                    if( menuItem.href == url.pathname ){
                        indiceActual = index;
                    }
                }
            }else{
                menuItem.sub.map((menuSubItem, indexSub, menuSub)=>{
                    if(Object.keys(propiedades).length > 0 && propiedades.props.location.state ){
                        if( menuSubItem.href == url.pathname+"/"+propiedades.props.location.state.filter ){
                            indiceActual = index;
                        }
                    }else{
                        if( menuSubItem.href == url.pathname ){
                            indiceActual = index;
                        }                        
                    }
                })
            }
        })

        handleOpen(null, indiceActual);
    }

    const handleOpen = (e, indice) => {
        const menus = document.querySelectorAll(".menuSup");
        for(let i=0; i<menus.length; i++){
            if( menus[i].id == "menuSup"+indice ){
                menus[i].classList.toggle("menu-open");
                if( e == null){                    
                    menus[i].children[0].classList.toggle("active");
                    if( menus[i].children.length > 1 ){
                        if( document.querySelector("#"+menus[i].children["sub"+indice].id) ){
                            const subMenu = document.querySelector("#"+menus[i].children["sub"+indice].id);
                            if( subMenu ){
                                for(let i=0; i<subMenu.children.length; i++){
                                    if(Object.keys(propiedades).length > 0  && propiedades.props.location.state){
                                        if( subMenu.children[i].children[0].dataset.href == url.pathname+"/"+propiedades.props.location.state.filter ){
                                            subMenu.children[i].children[0].classList.add("active");
                                            subMenu.children[i].classList.toggle("active");
                                        }                                    
                                    }
                                }
                            }
                        }
                    }else{
                        menus[i].children[0].classList.add("active");
                    }
                }
            }else{
                if( e == null ){
                    menus[i].children[0].classList.remove("active");
                }
            }
        }
        saveOpen(indice);
    }



    return(

        
            menu != null
            ?
                  <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" role="menu" data-accordion="true">

                            {
                                menu.map((menuItem, index, menu) => {

                                    let item;

                                    if( menuItem.sub === "" ){
                                        item = 
                                            <li key={index} id={"menuSup"+index} className="menuSup nav-item">
                                                <NavLink data-href={menuItem.href} to={menuItem.href} className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft : "10px"}} >
                                                    <i className={menuItem.icono} />
                                                    <p>{menuItem.title}</p>
                                                </NavLink>
                                            </li>
                                        ;
                                    }else{
                                        item = 
                                            <li key={index} id={"menuSup"+index} className={ open === {index} || actual === {index} ? "menuSup nav-item2 menu-open " : "menuSup nav-item2 "}  onClick={ e => handleOpen(e, index) } >
                                                <a href="#" className={ actual == {index} ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                                                    <i className={menuItem.icono}></i>
                                                    <p>
                                                        {menuItem.title}
                                                        <i className="fas fa-angle-left right"></i>
                                                    </p>
                                                </a>
                                                <ul id={"sub"+index} className="nav nav-treeview sub">
                                                    {
                                                        menuItem.sub.map((menuSubItem, indexSub, menuSub)=>{
                                                            
                                                            let item;
                                                            if( menuSubItem.href.includes("http") ){
                                                                item = 
                                                                    <li key={indexSub} className="nav-item">
                                                                        <a data-href={menuSubItem.href} href={menuSubItem.href} target='_blank' className="nav-link"  >
                                                                            <i className={confApp.menusuperadmin.informes.subinformes.activity.icono} style={{marginRight:5}} />
                                                                            <p>{confApp.menusuperadmin.informes.subinformes.activity.title}</p>
                                                                        </a>
                                                                    </li> 
                                                            }else{
                                                                item = 
                                                                    <li key={indexSub} className="nav-item">
                                                                        <NavLink data-href={menuSubItem.href} to={menuSubItem.href} className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                                                            <i className={menuSubItem.icono} style={{marginRight:5}} />
                                                                            <p>{menuSubItem.title}</p>
                                                                        </NavLink>
                                                                    </li>  
                                                            }

                                                            return(  
                                                                item
                                                            )

                                                        })
                                                    }

                                                </ul>
                                             </li>
                                    }

                                    return(
                                        item
                                    )
                                })
                            }
                        </ul>
                    </nav>
            :
                null       
    )
}

export default MenuCustom;
import React, { useEffect, useContext, useState, Fragment } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Kpi from '../../componentes/ui/Kpi';
import Section from '../../componentes/ui/Section';
import { Alert } from '../../componentes/ui/Alert';
import { getIndexUrl } from '../../helpers/helperApp';
import MiDataTableAlarmasMo from '../../componentes/formulario/TableAlarmasMo/MiDataTableAlarmasMo';




function Inicio(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, menuMode } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = confPantalla.config.trad.alarmas.title+" - "+confPantalla.index.title;             
                    saveLoadedConf(true);                    
                    if( menuMode === "checklist" ){                        
                        props.history.push(getIndexUrl(menuMode));
                    }        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
        
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])

    return(
        <div className="App wrapper">
                <Alert fondo={true} />    
                {
                    confPantalla !== undefined
                    ?
                        <Fragment>
                            <Menusup 
                                props = { props }
                            />
                            <Sidebar 
                                props = { props }
                            />
                        </Fragment>
                    :
                    null
                } 
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                        {
                            loadedConf === true && confPantalla !== undefined
                            ?
                                <div>                                  
                                    <MiDataTableAlarmasMo
                                        id =         { confPantalla.argosAlarmas.inicio.tablealarmasmo.id }
                                        section =    { confPantalla.argosAlarmas.inicio.tablealarmasmo.section }     
                                        loading=     { false }
                                        formulario = { {id:0} }
                                        table =      { confPantalla.argosAlarmas.inicio.tablealarmasmo }
                                        traduction=  { confPantalla.config.trad.components.datatable }
                                    />             
                                </div>                             
                            :
                                <LoadingPage />
                        }
                </section>
            </div>
            <Footer />        
        </div>
    );
}

export default Inicio
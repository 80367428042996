import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";
import axios from 'axios';
import Cookies from 'universal-cookie';
import Section from '../../ui/Section';
import { Modal } from 'react-bootstrap';
import FormInput from '../FormInput';
import TextArea from '../TextArea';
import FormRow from '../FormRow';
import Spinner from 'react-bootstrap/Spinner';
import MiSelect from '../MiSelect';


import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, axiosPost } from '../../../helpers/helperApp';

import TableMiDataTableAlarmasMo from './TableMiDataTableAlarmasMo';

require('datatables.net-dt');

$.DataTable = DataTable;
let filtro1, filtro2, filtro3, filtro4;

function MiDataTableAlarmasMo(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source(); 
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();  
    const source4 = CancelToken.source();  

    const { apiKey, forceGlobalReload, saveForceGlobalReload } = useContext(LoginContext);

    const { id, section, loading, formulario, table, traduction } = props;

    const [datosTable, saveDatosTable] = useState(null);
    const [loadingTable, saveLoadingTable] = useState(false);
    const [alarma, saveAlarma] = useState();
    const [showModal,saveShowModal] = useState(false);
    const [paginaActual, savePaginaActual] = useState(null);
    const [reseteando, saveReseteando] = useState(false);
    const [msgReseteando, saveMsgReseteando] = useState("");
    const [resultadoReset, saveResultadoReset] = useState(null);
    const [idCentro, saveIdCentro] = useState();
    const [idAlarma, saveIdAlarma] = useState();
    filtro1 = localStorage.getItem("filalar") ? localStorage.getItem("filalar").split(',')[0] : "false";
    filtro2 = localStorage.getItem("filalar") ? localStorage.getItem("filalar").split(',')[1] : "false";
    filtro3 = localStorage.getItem("filalar") ? localStorage.getItem("filalar").split(',')[2] : "false";
    filtro4 = localStorage.getItem("filalar") ? localStorage.getItem("filalar").split(',')[3] : "false";
     
    useEffect( () => {   

        // getDataDataTable(val)
        getDataDataTable()
        .then((res) => {
            saveDatosTable(res);
        });

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
            source3.cancel();
        } 

    },[ loading, formulario.id])

    const getDataDataTable = async () =>{

        saveLoadingTable(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("filtro1", filtro1);
        data.append("filtro2", filtro2);
        data.append("filtro3", filtro3);
        data.append("filtro4", filtro4);
        // const res = await axios.post(getApiUrl(window.location.origin)+table.data.url, data);
        const res = await axiosPost(getApiUrl(window.location.origin)+table.data.url, data, source.token);

        return res.data;
    }

    const clickAlarma = async (e, rowData) => {

        // Borramos el posible mensaje que existiese de reseteos anteriores
        saveResultadoReset(null);

        // Nos quedamos con la página actual del datatable
        let tabla=$('#'+id).DataTable();
        let info = tabla.page.info()

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("id", rowData.id);
        saveIdAlarma(rowData.id);
        data.append("idcentro", rowData.idcentro);
        saveIdCentro(rowData.idcentro);        
        const res = await axiosPost(getApiUrl(window.location.origin)+table.datalog.url, data, source2.token);
        saveAlarma(res.data[0]);
        saveShowModal(true);
        const newData = await getDataDataTable();
        // Guardamos la página actual
        savePaginaActual(info.page);

        // Refrescamos los datos de la tabla
        saveDatosTable(newData);

        // Modificamos el state global para forzar reload de los componentes que nos interesen.En este caso Notifications
        saveForceGlobalReload(forceGlobalReload => forceGlobalReload+1);

    }

    const changeSelectAccion = async(selectedItem, nameOfComponent) => {
        saveReseteando(true);
        saveResultadoReset(null);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("accion", selectedItem.value);
        data.append("id", idAlarma);
        data.append("idAlarma", alarma.idalarma);
        data.append("idcentro", idCentro);
        const res = await axiosPost(getApiUrl(window.location.origin)+table.accion.dataaccion, data, source3.token);
        if( typeof res.data != "string" ){
            saveReseteando(false)
            saveResultadoReset(true); 
            saveAlarma(res.data[0]);
            saveMsgReseteando(table.accion.msgok.title);
            
            // Refrescamos los datos de la tabla
            const newData = await getDataDataTable();       
            saveDatosTable(newData);
        }else{
            saveResultadoReset(false);
            saveMsgReseteando(res.data);
            saveReseteando(false)
        }

        // Modificamos el state global para forzar reload de los componentes que nos interesen.En este caso Notifications
        saveForceGlobalReload(forceGlobalReload => forceGlobalReload+1);

    }

    // const resetAlarma = async (idAlarma) =>{
    //     saveReseteando(true);        
    //     const data = new FormData();
    //     data.append("validacion", apiKey());
    //     data.append("id", idAlarma);
    //     const res = await axiosPost(getApiUrl(window.location.origin)+table.modal.resetalarma.data, data, source3.token);
    //     if( typeof res.data == "number" ){
    //         saveResultadoReset(true);
    //         saveMsgReseteando(table.modal.resetalarma.msgok.title);
    //     }else{
    //         saveResultadoReset(false);
    //         saveMsgReseteando(res.data);
    //     }

    //     saveReseteando(false);        
    // }

    const clickFiltro = async (numFiltro) => {
        switch(numFiltro){
            case 1:
                filtro1 = filtro1 == "true" ? "false" : "true";
                break;
            case 2:
                filtro2 = filtro2 == "true" ? "false" : "true";
                break;
            case 3:
                filtro3 = filtro3 == "true" ? "false" : "true";
                break;
            case 4:
                filtro4 = filtro4 == "true" ? "false" : "true";
                break;
        }

        localStorage.setItem("filalar", `${filtro1},${filtro2},${filtro3},${filtro4}`);
        // Modificamos el state global para forzar reload de los componentes que nos interesen.En este caso Notifications
        saveForceGlobalReload(forceGlobalReload => forceGlobalReload+1);

        // Refrescamos los datos de la tabla
        const newData = await getDataDataTable();       
        saveDatosTable(newData);
        
    }

    return(
        <Section titulo={section.titulo} icono={section.icono} col={ "12" } >
            <div className='mb-2 text-center'>
                <button type="button" className={filtro1 == "true" ? "mr-4 mt-2 btn-lg btn btn-outline-danger active" : "mr-4 mt-2 btn-lg btn btn-outline-danger"} onClick={e => clickFiltro(1)} >
                    {table.filtros.filtro1.title}
                </button>
                <button type="button" className={filtro2 == "true" ? "mr-4 mt-2 btn-lg btn btn-outline-warning active" : "mr-4 mt-2 btn-lg btn btn-outline-warning"} onClick={e => clickFiltro(2)} >
                    {table.filtros.filtro2.title}
                </button>
                <button type="button" className={filtro3 == "true" ? "mr-4 mt-2 btn-lg btn btn-outline-primary active" : "mr-4 mt-2 btn-lg btn btn-outline-primary"} onClick={e => clickFiltro(3)} >
                    {table.filtros.filtro3.title}
                </button>
                <button type="button" className={filtro4 == "true" ? "mr-4 mt-2 btn-lg btn btn-outline-success active" : "mr-4 mt-2 btn-lg btn btn-outline-success"} onClick={e => clickFiltro(4)} >
                    {table.filtros.filtro4.title}
                </button>
            </div>
            <TableMiDataTableAlarmasMo 
                id={ id }
                columnas={ table.columns }
                data={ datosTable }
                buttons={ table.buttons }
                ordering={ table.ordering }
                order={ table.order }
                searching={ table.searching }
                paging={ table.paging }
                select={ table.select }
                columnactions={ table.columnactions.title }
                traduction={ traduction }
                linkColumns = {table.linkColumns }
                pagelength = { table.pagelength }
                loadingTable = { loadingTable }
                saveLoadingTable = { saveLoadingTable }
                functionClickTd={ clickAlarma }
                columnsFunctionClickTd={[0,1,2,3]}
                paginaActual={paginaActual}
            />
            {
                showModal
                ?
                    <Modal
                        size="lg"
                        show={true}
                        onHide={ () => saveShowModal(false)}                            
                        aria-labelledby="example-custom-modal-styling-title"
                        centered={true}
                        contentClassName=""
                        backdropClassName="backLikeSw"                                              
                    >                          
                        <Modal.Body>
                            <FormRow>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.idalarma }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.id.title }
                                    placeholder={ "" }    
                                    col=        { "4" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.evares }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.evares.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "4" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.evaval }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.evaval.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "4" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                            </FormRow>
                            {/* <FormRow>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.nivel }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.nivel.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "6" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.evafh }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "datetime" }  
                                    name=       { "" }   
                                    label=      { table.modal.evafh.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "6" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                            </FormRow> */}
                            {/* <FormRow>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.refsupinf == 0 ? "" : alarma.refsupinf }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.refsupinf.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "4" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.rangomin == 0 ? "" : alarma.rangomin }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.rangomin.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "4" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.rangomax == 0 ? "" : alarma.rangomax }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.rangomax.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "4" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                            </FormRow> */}
                            <FormRow>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.nombre }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.nombre.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                            </FormRow>
                            <FormRow>
                                <TextArea                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.sensor }
                                    loading=    { false }
                                    id=         { "" }   
                                    name=       { "" }
                                    rows=       { "2" }      
                                    label=      { table.modal.sensor.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />
                            </FormRow>
                            {/* <FormRow>
                                <TextArea                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.descripcion }
                                    loading=    { false }
                                    id=         { "" }   
                                    name=       { "" }
                                    rows=       { "4" }      
                                    label=      { table.modal.descripcion.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />                          
                            </FormRow> */}
                            <FormRow>
                                <TextArea                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.evamsg }
                                    loading=    { false }
                                    id=         { "" }   
                                    name=       { "" }
                                    rows=       { "4" }      
                                    label=      { table.modal.evamsg.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />                                                                             
                            </FormRow>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { "" }
                                    val=         { alarma.accion }   
                                    loading=     { false }
                                    id=          { table.accion.select.id }    
                                    name=        { table.accion.select.name }   
                                    label=       { table.accion.select.label }    
                                    placeholder= { table.accion.select.placeholder }
                                    col=         { 12 } 
                                    isClearable= { table.accion.select.isclearable }
                                    isSearchable={ table.accion.select.issearchable }
                                    dataType=    { table.accion.select.datatype }
                                    data=        { table.accion.select.data }
                                    required=    { false } 
                                    disabled=    { false }
                                    readOnly =   { false }
                                    disablethisid={false}
                                    disableids=   {false}
                                    formulario= { {id:0} }   
                                    change= { changeSelectAccion }
                                />
                            </FormRow>                            
                            {   reseteando ? 
                                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                        <Spinner animation="border" />
                                    </div>
                                :
                                null
                            }
                            {
                                resultadoReset === true || resultadoReset === false
                                ?
                                    <div className={
                                            resultadoReset === true 
                                            ? 
                                                "alert alert-success text-center" 
                                            : 
                                                resultadoReset ===  false 
                                            ? 
                                                "alert alert-danger text-center" 
                                            : 
                                                ""
                                            } 
                                    role="alert">
                                        {msgReseteando}
                                    </div>                                                                    
                                :
                                    null
                            } 
                            {/* <button className='btn btn-danger float-right' onClick={e => resetAlarma(alarma.idalarma)}>{ table.modal.btnreset.title }</button> */}
                            {/* <FormRow>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { alarma.destinatarios }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { table.modal.destinatarios.title }                                                       
                                    placeholder={ "" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { true }                                                
                                    disabled=   { false }
                                    change=     { null }
                                    onBlur=     { null } 
                                />                                                     
                            </FormRow> */}
                        </Modal.Body>                            
                    </Modal>
                :
                    null
            }                  
        </Section>
    )
}

export default MiDataTableAlarmasMo;
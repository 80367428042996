import React from 'react';

function Section(props){
    
    const {titulo, icono, col, clases, idPrin, idHijo, wiki, borderHeader, recurrencia } = props;

    return(
            <section className={clases ? `${clases} content col-md-${col} connectedSortable ui-sortable` : ` content col-md-${col} connectedSortable ui-sortable`} style={{paddingLeft : "0px", paddingRight : "0px" }} >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                { titulo !== '' && titulo != undefined
                                ?
                                    <div className="card-header">
                                        <h3 className="card-title"                                    
                                            style={ 
                                                borderHeader != null && borderHeader != "" 
                                                ? 
                                                    {borderBottom: "3px solid "+borderHeader, paddingBottom: "5px"  } 
                                                : 
                                                    {} 
                                                } 
                                        >
                                            <i className={icono} style={ 
                                                borderHeader != null && borderHeader != "" 
                                                ? 
                                                    {color: borderHeader, marginRight: 5  } 
                                                : 
                                                    {marginRight: 5  }  
                                                } >                                                
                                            </i>
                                            {
                                                !titulo.includes("undefined") 
                                                ? 
                                                    titulo 
                                                :
                                                    null
                                            }
                                        </h3>                                      
                                        {
                                            wiki
                                            ?
                                                <a href={wiki} target="_blank" style={{color: "inherit"}} className="ml-1"><i className="fal fa-question-circle"></i></a>
                                            :   
                                                null
                                        }
                                        {
                                            idPrin != "" && idHijo != "" && idPrin != undefined && idHijo != undefined
                                            ?
                                            <small className="text-muted ml-1">{"- "}{idPrin}{"/"}{idHijo}</small>
                                            :
                                                (idPrin > 0 && idHijo == "") || (idPrin > 0 && idHijo == undefined) 
                                                ?
                                                <small className="text-muted ml-1">{"- "}{idPrin}</small>
                                                :
                                                null
                                        }
                                        {
                                            recurrencia 
                                            ? 
                                                <span className='alert-primary pl-3 pr-3 pt-2 pb-2 float-right' style={{borderRadius: "5px"}} >
                                                        {recurrencia}
                                                </span>
                                            :
                                                null
                                        }
                                    </div>
                                :
                                null
                                }
                                <div className="card-body pad table-responsive">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Section;
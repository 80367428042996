import React, { useEffect, useState, Fragment } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';


import { getPantDocFromNivel } from '../../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();

var botones = [
    {
        extend: 'pageLength',
        text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        // titleAttr: 'Ver número de registros'
    },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    {
        extend: 'colvis',
        text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
    }
];

function TableMiDataTableAlarmasMo(props){

    const cookies = new Cookies();

    // ***** props *****
    const { id, data, columnas } = props;
    let { buttons, ordering, order, searching, paging, select, columnactions, traduction, linkColumns, pagelength, loadingTable, saveLoadingTable, functionClickTd, columnsFunctionClickTd, paginaActual } = props;

    
    
    useEffect(()=>{
        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);

        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        // Añadimos los enlaces a los td
        for( let i = 0; i < columnas.length; i++ ){
            columnas[i].createdCell = (td, cellData, rowData, row, col) => {
                if( linkColumns != undefined ){
                     // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                    if ('style' in rowData){
                        $(td).addClass(rowData["style"]);
                    }
                    if( linkColumns[i] != undefined && linkColumns[i] != "" ){
                        // Si el enlace es a la pantalla de documentos obtenemos la pantalla que le corresponde según su id
                        if( linkColumns[i].link == "/documentos" ){
                            linkColumns[i].link = getPantDocFromNivel(cookies.get('nivel'));
                        }
                        if( linkColumns[i].data.includes("/") && linkColumns[i].link != "" ){
                            const links = linkColumns[i].data.split("/");
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[links[0]]+"/"+rowData[links[1]]+"' target='_blank'>"+cellData+"</a>");
                        }else if( linkColumns[i].link != "" ){
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[linkColumns[i].data]+"' target='_blank'>"+cellData+"</a>");
                        }
                    }
                    if( functionClickTd ){
                        if( columnsFunctionClickTd ){
                            if( columnsFunctionClickTd.includes(col) ){
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }
                        }else{
                            $(td).on('click', function (e) {
                                e.preventDefault();
                                functionClickTd(e, rowData);
                            });
                        }                        
                    }
                }
            }
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        select === undefined ? select = false : select = select;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;

        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({
                        mark: true,
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        }
                    })
                }  
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{               
                    $("."+id).DataTable({
                        mark: true,
                        dom: buttons,
                        data: null,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }         
                        }
                    })
                }
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({
                    mark: true,
                    dom: buttons,
                    data: null,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }       
                    }
                })
            }
        }    
        
        saveLoadingTable(false);
        if(paginaActual != null){
            let oTable = $("."+id).DataTable();
            oTable.page(paginaActual).draw( false );
        }

    }, [data])

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
        
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
    
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas

    return(
        <Fragment> 
            {   loadingTable ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }         
            <div className="form-group col-md-12" style={{overflowX: "auto"}}>
                <table id={id} className={id+" table table-sm table-bordered table-striped table-hover dataTable"} style={{width:"100%", cursor:"pointer"}}></table>
            </div>               
        </Fragment>
    )
}

export default TableMiDataTableAlarmasMo;
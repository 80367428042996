import { useState, useContext, useEffect } from 'react';
import $ from 'jquery'


import Cookies from 'universal-cookie';

import { alertService } from '../services/alertservice';
import axios from 'axios';
import swal from 'sweetalert';
import { setDeleteInputError, deleteClass, getApiUrl } from '../helpers/helperApp';


import { LoginContext } from './context/LoginContext';

function useLogicForm(){

    const cookies = new Cookies();

    const { apiKey, checkUser, checkEmail, socket, concurrencia, getValidaciones, inicioValidaciones, saveConcurrencia, checkConcurencia, confApp } = useContext(LoginContext);

    /***** States *****/
        // Formulario principal
        const [registrosPrin, saveRegistrosPrin] = useState([]);
        const [formularioPrin, saveFormularioPrin] = useState({});
        const [adjuntosPrin, saveAdjuntosPrin] = useState([]);
        const [adjuntosExistentesPrin, saveAdjuntosExistentesPrin] = useState([]);

        // Formulario hijo
        const [registrosHijo, saveRegistrosHijo] = useState([]);
        const [formularioHijo, saveFormularioHijo] = useState({});
        const [adjuntosHijo, saveAdjuntosHijo] = useState([]);
        const [adjuntosExistentesHijo, saveAdjuntosExistentesHijo] = useState([]);

        // Spinners
        const [loadingTable, saveLoadingTable] = useState(true);
        const [loadReg, saveLoadReg] = useState(false);
        
        // Ids de formularios
        const [idPrin, saveIdPrin] = useState(null);
        const [idHijo, saveIdHijo] = useState(null);

        // Mostrar modal cambio de contraseña
        const [showChangePass, saveShowChangePass] = useState(false);

        // State para la imagen de cabecera
        const [imgCabecera, saveImgCabecera] = useState("");

        // State para filtrar los registros según el parámetro que recibimos. Ej.: /appcfg/login
        const [filterRegs, saveFilterRegs] = useState(""); 
        
        // State para comprobar cuando finalizó la carga de los hijos. Lo estamos utilizando en los wizard para evitar el mensaje de "NO DISPONIBLE" mientras no terminan de cargar los hijos
        const [loadedHijos, saveLoadedHijos] = useState(false);
        
    /***** Fin: States *****/
    
    /***** Handle Functions *****/
        // ***** Leer valores del formulario y guardarlo en el state del form *****
        const handleInputForm = (e, formulario, saveFormulario) => {
            const { name, value } = e.target;
            saveFormulario({ ...formulario, [name]:value })        
        }

        // ***** Guarda los valores de los combos del formulario principal en el state *****
        const handleSelectFormPrin = (selectedItem, nameOfComponent) => {

            if( selectedItem ){
                saveFormularioPrin({ ...formularioPrin, [nameOfComponent.name]:selectedItem.value })  
            }else{
                saveFormularioPrin({ ...formularioPrin, [nameOfComponent.name]:"" })
            }
        }

        // ***** Guarda los valores de los combos del formulario principal en el state Y HACE UNA LLAMADA PARA ESTABLECER UN VALOR EN OTRO COMBO*****
        const handleSelectFormPrinAndSetValue = async (selectedItem, nameOfComponent, setvalue) => {

            // Si queremos seleccionar una opción en función de lo seleccionado en otro control
            if( setvalue != null && setvalue != "" ){

                if( selectedItem ){
                    const data = new FormData();
                    const validacion = apiKey();
                    data.append("validacion", validacion);
                    data.append("value", selectedItem.value);
                    const respuesta = await axios.post(getApiUrl(window.location.origin)+setvalue.action,data);
    
                    if( respuesta.data.length > 0 ){
                        if( respuesta.data[0][setvalue.control] != null && respuesta.data[0][setvalue.control] != "0"  ){
                            // Si recibimos algo en la respuesta establecemos el valor                        
                            saveFormularioPrin(
                                {   ...formularioPrin, 
                                    [setvalue.control] : respuesta.data[0][setvalue.control],
                                    [nameOfComponent.name]:selectedItem.value
                                }
                            ) 
                        }else{
                            // Si no recibimos nada en el array vaciamos el select
                            saveFormularioPrin(
                                {   ...formularioPrin, 
                                    [setvalue.control] : "",
                                    [nameOfComponent.name]:selectedItem.value
                                }
                            ) 
                        }                       
                    }else{
                            // Si no recibimos nada en el array vaciamos el select
                            saveFormularioPrin(
                                {   ...formularioPrin, 
                                    [setvalue.control] : "",
                                    [nameOfComponent.name]:selectedItem.value
                                }
                            ) 
                    }
                }else{
                    saveFormularioPrin(
                        {   ...formularioPrin, 
                            [setvalue.control] : "",
                            [nameOfComponent.name]:""
                        }
                    ) 
                }


            }

        }

        // ***** Guarda los valores de los combos del formulario hijo en el state *****
        const handleSelectFormHijo = (selectedItem, nameOfComponent) => {
            if( selectedItem ){
                saveFormularioHijo({ ...formularioHijo, [nameOfComponent.name]:selectedItem.value })  
            }else{
                saveFormularioHijo({ ...formularioHijo, [nameOfComponent.name]:"" })  
            }
        }

        // ***** Guarda los valores de los combos del formulario hijo en el state Y HACE UNA LLAMADA PARA ESTABLECER UN VALOR EN OTRO COMBO*****
        const handleSelectFormHijoAndSetValue = async (selectedItem, nameOfComponent, setvalue, descripcionLinea=null, descripcionLineaIngles=null) => {

            // Si queremos seleccionar una opción en función de lo seleccionado en otro control
            if( setvalue != null && setvalue != "" ){
                if( selectedItem ){
                    const data = new FormData();
                    const validacion = apiKey();
                    data.append("validacion", validacion);
                    data.append("value", selectedItem.value);
                    const respuesta = await axios.post(getApiUrl(window.location.origin)+setvalue.action,data);
    
                    if( respuesta.data.length > 0 ){
                        if( respuesta.data[0][setvalue.control] != null ){
                            // Si recibimos algo en la respuesta establecemos el valor
                            if( descripcionLinea != null ){
                                saveFormularioHijo(
                                    {   ...formularioHijo, 
                                        [setvalue.control] : respuesta.data[0][setvalue.control],
                                        [nameOfComponent.name]:selectedItem.value,
                                        ["nom1"] : descripcionLinea,
                                        ["nom1_en"] : descripcionLineaIngles
                                    }
                                )
                            }else{
                                saveFormularioHijo(
                                    {   ...formularioHijo, 
                                        [setvalue.control] : respuesta.data[0][setvalue.control],
                                        [nameOfComponent.name]:selectedItem.value
                                    }
                                )
                            }                       
                        }else{
                            // Si no recibimos nada en el array vaciamos el select
                            if( descripcionLinea != null ){
                                saveFormularioHijo(
                                    {   ...formularioHijo, 
                                        [setvalue.control] : "",
                                        [nameOfComponent.name]:selectedItem.value,
                                        ["nom1"] : descripcionLinea,
                                        ["nom1_en"] : descripcionLineaIngles
                                    }
                                ) 
                            }else{
                                saveFormularioHijo(
                                    {   ...formularioHijo, 
                                        [setvalue.control] : "",
                                        [nameOfComponent.name]:selectedItem.value
                                    }
                                ) 
                            }
                        }                       
                    }else{
                            // Si no recibimos nada en el array vaciamos el select
                            if( descripcionLinea != null ){
                                saveFormularioHijo(
                                    {   ...formularioHijo, 
                                        [setvalue.control] : "",
                                        [nameOfComponent.name]:selectedItem.value,
                                        ["nom1"] : descripcionLinea,
                                        ["nom1_en"] : descripcionLineaIngles
                                    }
                                ) 
                            }else{
                                saveFormularioHijo(
                                    {   ...formularioHijo, 
                                        [setvalue.control] : "",
                                        [nameOfComponent.name]:selectedItem.value
                                    }
                                )                                 
                            }
                    }
                }else{
                    if( descripcionLinea != null ){
                        saveFormularioHijo(
                            {   ...formularioHijo, 
                                [setvalue.control] : "",
                                [nameOfComponent.name]:"",
                                ["nom1"] : "",
                                ["nom1_en"] : ""
                            }
                        )

                    }else{
                        saveFormularioHijo(
                            {   ...formularioHijo, 
                                [setvalue.control] : "",
                                [nameOfComponent.name]:""
                            }
                        )                        
                    }
                }

            }

        }

        // ***** Guarda los valores de los combos multiples del formulario principal en el state *****
        const handleSelectMultipleFormPrin = (selectedItem, nameOfComponent) => {
            let optionsSeleccionadas = [];
            let nombre = nameOfComponent.name.split("[]")[0];
            
            for(let i = 0; i < selectedItem.length; i++){
                optionsSeleccionadas.push(selectedItem[i].value);
            }
            
            if( selectedItem ){
                saveFormularioPrin({ ...formularioPrin, [nombre]:optionsSeleccionadas }) 
            }else{
                saveFormularioPrin({ ...formularioPrin, [nameOfComponent.name]:[] })
            }
        }

        // ***** Guarda los valores de los combos multiples del formulario hijo en el state *****
        const handleSelectMultipleFormHijo = (selectedItem, nameOfComponent) => {
            let optionsSeleccionadas = [];
            let nombre = nameOfComponent.name.split("[]")[0];
            
            for(let i = 0; i < selectedItem.length; i++){
                optionsSeleccionadas.push(selectedItem[i].value);
            }
            
            if( selectedItem ){
                saveFormularioHijo({ ...formularioHijo, [nombre]:optionsSeleccionadas }) 
            }else{
                saveFormularioHijo({ ...formularioHijo, [nameOfComponent.name]:[] })
            }
        }

        // ***** Seleccionar un registro con el id que recibimos por la url *****
        const handleLastReg = async (idPrin, idHijo, urlsPrin, urlsHijo, tabla, paramEstado=null, saveEstadoFormPrin=null, saveEstadoFormHijo=null) => {

            // Cargamos el padre
            editReg(idPrin, urlsPrin, saveFormularioPrin, saveAdjuntosExistentesPrin, tabla);

            // Recibir solo el estado no tiene sentido porque no vas a poner el formulario en readonly sin un id de registro.
            if( idHijo == "readonly" ){
                // Recibimos un idPadre y el estado del formulario en el segundo parámetro. /pantalla/idPadre/estadoForm 
                // Cambiamos los estados del formulario padre           
                saveEstadoFormPrin(idHijo);
                saveEstadoFormHijo(idHijo); 
            }
            if( idHijo != "readonly" && idHijo != undefined && paramEstado == null ){
                // Recibimos un idPadre, un idHijo pero no recibimos un estado de formulario. /pantalla/idPadre/idHijo
                // Cargamos el hijo
                editReg(idHijo, urlsHijo, saveFormularioHijo, saveAdjuntosExistentesHijo, tabla, true);
            }
            if( idHijo != "readonly" && idHijo != undefined && paramEstado == "readonly" ){
                // Recibimos un idPadre, un idHijo y recibimos un estado de formulario. /pantalla/idPadre/idHijo/estadoFormulario
                // Cargamos el hijo
                editReg(idHijo, urlsHijo, saveFormularioHijo, saveAdjuntosExistentesHijo, tabla, true);
                // Cambiamos los estados del formulario padre e hijo
                saveEstadoFormPrin(paramEstado);
                saveEstadoFormHijo(paramEstado); 
            }

            if( urlsHijo ){
                if( urlsHijo.get != "" ){
                    await loadHijos(idPrin, urlsHijo);
                }
            }

        }

        // ***** Seleccionar un registro en los botones del formulario y de la tabla para editar *****
        const handleEditRegPrin = async (e, urlsPrin, urlsHijo, padresHijos, modelFormHijo, tabla) => {

            let id;

            if( typeof e === "object" ){
                e.stopPropagation();
                id = e.target.id;
            }else if( typeof e === "number" ){
                id = e;
            }
            saveIdPrin(id);
            saveAdjuntosExistentesPrin([]);
            editReg(id, urlsPrin, saveFormularioPrin, saveAdjuntosExistentesPrin, tabla, false, urlsHijo, modelFormHijo, padresHijos);
                                              
            // /***** Borramos las clases de validación de los input *****/
            // deleteClass("is-valid");
            // deleteClass("is-invalid");

            // if( padresHijos ){
            //     await regIniciales(saveRegistrosHijo, urlsHijo, id);
            //     resetFormHijo(modelFormHijo);
            // }
    
        }
        
        const loadHijos = async (idPadre, urlsHijo, modelFormHijo) =>{

            await regIniciales(saveRegistrosHijo, urlsHijo, idPadre);
            if(modelFormHijo != null){
                resetFormHijo(modelFormHijo);
            }
            saveLoadedHijos(true);
        }

        const handleEditRegHijo =  (e, urlsHijo, tabla) => {

            let id;
            if( typeof e === "object" ){
                e.stopPropagation();
                id = e.target.id;
            }else if( typeof e === "number" ){
                id = e;
            }

            saveIdHijo(id);
            saveAdjuntosExistentesHijo([]);
            editReg(id, urlsHijo, saveFormularioHijo, saveAdjuntosExistentesHijo, tabla, true);

            /***** Borramos las clases de validación de los input *****/
            deleteClass("is-valid");
            deleteClass("is-invalid");
            
        }
        
        // ***** Borrar registros. Dentro filtramos si estamos borrando desde la tabla o desde los botones del formulario *****
        const handleDeleteItemPrin = (e, urlsPrin, modelFormPrin, tabla, title=null) => {
            deleteItem(e, urlsPrin, formularioPrin, resetFormPrin, saveRegistrosPrin, null, modelFormPrin, tabla, true, false, null, null, title);
        }    

        // ***** Borrar registros. Dentro filtramos si estamos borrando desde la tabla o desde los botones del formulario *****
        const handleDeleteItemHijo = (e, urlsHijo, modelFormHijo, tabla, urlsPadre, tablaPadre, title=null) => { 
            let idPad;
            if( e ){
                idPad = e.target.dataset.idpadre;
            }else{
                idPad = formularioPrin.id;
            }
            deleteItem(e, urlsHijo, formularioHijo, resetFormHijo, saveRegistrosHijo, idPad, modelFormHijo, tabla, false, true, urlsPadre, tablaPadre, title);
            // deleteItem(e, urlsHijo, formularioHijo, resetFormHijo, saveRegistrosHijo, e.target.dataset.idpadre, modelFormHijo, tabla, false, true, urlsPadre, tablaPadre, title);
            // deleteItem(e, urlsHijo, formularioHijo, resetFormHijo, saveRegistrosHijo, formularioPrin.id, modelFormHijo, tabla, false, true, urlsPadre, tablaPadre, title);
        }  

        // ***** Submit del formulario Princiapal ******
        const handleSubmitPrin = (e, urlsPrin, modelFormPrin, tabla, modelFormHijo=null, title=null, noQuestion=null) => {

            // Borramos el filtro que pueda existir en los adjuntos plus ya que sino al tener registros filtrados, están con display none y no se envían por post con lo que se borrarían del registro.
            const tablasAdjPlus = document.querySelectorAll('.adjPlus');
            for( let i = 0; i < tablasAdjPlus.length; i++){
                const tabla = $('#'+tablasAdjPlus[i].id).DataTable();
                tabla.search('').draw();
            }            

            sendForm(e, urlsPrin, formularioPrin, saveFormularioPrin, resetFormPrin, saveRegistrosPrin, adjuntosPrin, saveAdjuntosPrin, saveAdjuntosExistentesPrin, null, modelFormPrin, tabla, false, null, null, modelFormHijo, title, noQuestion);
        }    

        // ***** Submit del formulario ******
        const handleSubmitHijo = (e, urlsHijo, modelFormHijo, tabla, urlsPadre, tablaPadre, title=null) => {

            // Borramos el filtro que pueda existir en los adjuntos plus ya que sino al tener registros filtrados, están con display none y no se envían por post con lo que se borrarían del registro.
            const tablasAdjPlus = document.querySelectorAll('.adjPlus');
            for( let i = 0; i < tablasAdjPlus.length; i++){
                const tabla = $('#'+tablasAdjPlus[i].id).DataTable();
                tabla.search('').draw();
            }  

            sendForm(e, urlsHijo, formularioHijo, saveFormularioHijo, resetFormHijo, saveRegistrosHijo, adjuntosHijo, saveAdjuntosHijo, saveAdjuntosExistentesHijo, formularioPrin.id, modelFormHijo, tabla, true, urlsPadre, tablaPadre, null, title);
        }

        // ***** Función que valida que el nombre de usuario sea único ******
        const handleCheckUser = async e => {
            validateUser(e, formularioPrin.id);
        }

        // ***** Función que valida que el email de usuario sea único ******
        const handleCheckEmail = async e => {
            validateEmail(e, formularioPrin.id);
        }

        // ***** Función que muestra el formulario para cambiar las contraseñas ******
        const handleChangePass = () => {
            saveShowChangePass(true);
        }

        // ***** Clonar registros Padre******
        const handleClonePrin = (id, urlsPrin, urlsHijo, padresHijos, modelFormHijo, tabla, title=null) => {

            if( id === "" ){
                alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.clone.empty.title}${id}`, {autoClose: true});
            }else{
                swal({
                    title: title,
                    text: confApp.config.trad.logic.clone.swal.title,
                    icon: "warning",
                    buttons: {
                        cancel : {text: confApp.config.trad.logic.clone.swal.button.cancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text: confApp.config.trad.logic.clone.swal.button.confirm.title, className:'clonar btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                    } 
                }).then(confirmacion => {
                    if(confirmacion){
                        clonarRegistroPrin(id, urlsPrin, urlsHijo, padresHijos, modelFormHijo, tabla);
                    }
                })
            }

            const clonarRegistroPrin = async (id, urlsPrin, urlsHijo, padresHijos, modelFormHijo, tabla) => {              

                // Hacemos la llamada
                const data = new FormData();
                data.append("id", id);
                const validacion = apiKey();
                data.append("validacion", validacion);
                const respuesta = await axios.post(getApiUrl(window.location.origin)+urlsPrin.clone,data);

                if( typeof respuesta.data !== "string" ){
                    if( respuesta.data.idClon ){
                        await handleEditRegPrin(respuesta.data.idClon, urlsPrin, urlsHijo, padresHijos, modelFormHijo, tabla);
                        if( urlsHijo.get != "" ){
                            await loadHijos(respuesta.data.idClon, urlsHijo, modelFormHijo, padresHijos);
                        }
                        
                        saveAdjuntosPrin([]);
                        saveAdjuntosHijo([]);

                        alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confApp.config.trad.logic.clone.success.title}`, {autoClose: true});
    
                        const registros = await getRegistros(null, urlsPrin);
                        if( registros !== null ){
                            saveRegistrosPrin(registros);     
                        }
                    }else{
                        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.logic.clone.error.title}`, {autoClose: true});
                    }
                }else if( typeof respuesta.data === "string" ){            
                        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});                
                }       
              
            }
        }

        // ***** Clonar registros Hijo******
        const handleCloneHijo = (id, urlsHijo, tabla, urlsPadre, tablaPadre, title=null) => {

            if( id === "" ){
                alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.clone.empty.title}${id}`, {autoClose: true});
            }else{
                swal({
                    title: title,
                    text: confApp.config.trad.logic.clone.swal.title,
                    icon: "warning",
                    buttons: {
                        cancel : {text: confApp.config.trad.logic.clone.swal.button.cancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text: confApp.config.trad.logic.clone.swal.button.confirm.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                    } 
                }).then(confirmacion => {
                    if(confirmacion){
                        clonarRegistroHijo(id, urlsHijo, tabla, urlsPadre, tablaPadre);
                    }
                })
            }

            const clonarRegistroHijo = async (id, urlsHijo, tabla, urlsPadre, tablaPadre) => {              

                // Hacemos la llamada
                const data = new FormData();
                data.append("id", id);
                const validacion = apiKey();
                data.append("validacion", validacion);
                const respuesta = await axios.post(getApiUrl(window.location.origin)+urlsHijo.clone,data);

                if( typeof respuesta.data !== "string" ){
                    if( respuesta.data.idClon ){
                        await handleEditRegHijo(respuesta.data.idClon, urlsHijo, tabla);
                        
                        saveAdjuntosPrin([]);
                        saveAdjuntosHijo([]);

                        alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confApp.config.trad.logic.clone.success.title}`, {autoClose: true});
    
                        const registros = await getRegistros(null, urlsHijo, idPrin);
                       
                        if( registros !== null ){
                            saveRegistrosHijo(registros);     
                        }

                        // Volvemos a cargar el padre para ver las actualizaciones de las valoraciones en el padre                       
                        await handleEditRegPrin(Number(idPrin), urlsPadre, null, false, null, tablaPadre);
                    
                    }else{
                        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.logic.clone.error.title}`, {autoClose: true});
                    }
                }else if( typeof respuesta.data === "string" ){            
                        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});                
                } 

               
            }
        }
    /***** Fin: Handle Functions *****/
    
    /***** CRUD Functions *****/
        // ***** Seleccionar un registro en los botones del formulario y de la tabla para editar *****
        const editReg = async (id, urls, saveFormulario, saveAdjuntosExistentes, tabla, esHijo=false, urlsHijo=null, modelFormHijo=null, padresHijos=null) => { 
            // Hacemos scroll hasta el principio de la pantalla
            $('html,body').animate({scrollTop:0},800);
                               
                const editar = async (id, urls, saveFormulario, saveAdjuntosExistentes, tabla, concurrenciaAux) => {

                    saveLoadReg(true);
                    const registro = await getRegistros(id, urls);

                    if( registro !== null && registro !== undefined ){
                        /***** Borramos las clases de validación de los input *****/
                        deleteClass("is-valid");
                        deleteClass("is-invalid");

                        saveFormulario(registro[0]);

                        saveAdjuntosPrin([]);
                        saveAdjuntosHijo([]);

                        //Reseteamos los input de los adjuntos
                        let adjs = document.querySelectorAll("input[type=file]");
                        for(let i=0; i<adjs.length; i++){
                            adjs[i].value = null;
                            adjs[i].files = null;
                        }

                        saveAdjuntosExistentes(registro.adjuntos);
                    }else if( registro === undefined ){
                        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.logic.edit.noid.title}${id}`, {autoClose: true});            
                        if( concurrenciaAux === "1" ){
                            if( socket != null && socket.connected == true ){
                                socket.emit("resetForm", { "idusu": cookies.get('id'), "tabla": tabla })
                            }
                        }
                    }
                    saveLoadReg(false)
                } 
                
                // Comprobamos con la bbdd la si la concurrencia esta activada ya que cuando entramos directamente en un registro enviándole como parámetro en la url su id, o abrimos otra pestaña de la misma pantalla esté dato no está disponible, mientras que si hacemos una navegación normal por los menús si que está disponible y ahí ya no hace falta consultarlo.
                let concurrenciaAux;
                if( concurrencia === "" ){
                    saveLoadReg(true);
                    concurrenciaAux = await checkConcurencia();
                }else{
                    concurrenciaAux = concurrencia;
                }

                if( (concurrencia === "1" || concurrenciaAux === "1") && esHijo != true ){
                    if( socket != null && socket.connected == true ){
                        // Envío una consulta al servidor con mis datos y los del registro que quiero editar para consultar si esta libre
                        socket.emit("regOcupado", { "url": window.location.origin, "idusu": cookies.get('id'), "nombre": cookies.get('nombre'), "idreg": id, "tabla": tabla  }, (response) => {
                            if( response["response"].length > 0 ){
                                alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.edit.busy.title} '${response["response"][0]["nombre"]}' - ${response["response"][0]["hora"]}`, {autoClose: true}); 
                                saveLoadReg(false);  
        
                            }else if( response["response"].length === 0 ){
                                editar(id, urls, saveFormulario, saveAdjuntosExistentes, tabla, concurrenciaAux);                             
                                if( padresHijos ){
                                    loadHijos(id, urlsHijo, modelFormHijo);
                                }
                            }
                        })  
                    }else{
                        editar(id, urls, saveFormulario, saveAdjuntosExistentes, tabla, concurrenciaAux);                             
                        if( padresHijos ){
                            loadHijos(id, urlsHijo, modelFormHijo);
                        }
                    }
                }else{
                    editar(id, urls, saveFormulario, saveAdjuntosExistentes, tabla, concurrenciaAux);
                    if( padresHijos ){
                        loadHijos(id, urlsHijo, modelFormHijo);
                    }
                } 
                
        }

        // ***** Borrar registros. Dentro filtramos si estamos borrando desde la tabla o desde los botones del formulario *****
        const deleteItem = async (e, urls, formulario, resetForm, saveRegistros, idPadre=null, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre=null, tablaPadre=null, title) => {        

            if( e !== undefined || formulario.id !== "" ){
                    swal({
                        title: title,
                        text: confApp.config.trad.logic.delete.swal.title,
                        icon: "warning",
                        buttons: {
                            cancel : {text: confApp.config.trad.logic.delete.swal.button.cancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: confApp.config.trad.logic.delete.swal.button.confirm.title, className:'btn btn-flat mr-1 btn-outline-danger btnSwalDelete'}
                        } 
                    }).then(confirmacion => {
                        if(confirmacion){
                            borrarRegistro(e, urls, formulario, resetForm, saveRegistros, idPadre, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre, tablaPadre);
                        }
                    })
            }else{      
                    alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.delete.empty.title}`, {autoClose: true});
            }

            const borrarRegistro = async (e, urls, formulario, resetForm, saveRegistros, idPadre, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre, tablaPadre) => {
                                  
                const borrar = async (e, urls, formulario, resetForm, saveRegistros, idPadre, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre, tablaPadre,  idRegDelete, idActual) => {

                    //Colocamos el spinner
                    saveLoadReg(true);

                    // enviamos la petición
                    const data = new FormData();
                    data.append("id", idRegDelete);
                    const validacion = apiKey();
                    data.append("validacion", validacion);
                    const respuesta = await axios.post(getApiUrl(window.location.origin)+urls.delete,data);
    
                    //Borramos el spinner
                    saveLoadReg(false);
                
                    if( typeof respuesta.data !== "string" ){
                        if( respuesta.data !== 0 ){
                            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confApp.config.trad.logic.delete.success.title}`, {autoClose: true});
                            // reseteamos el formulario si estamos borrando el mismo id que estaba en el formulario

                            if( idRegDelete === formulario.id || idRegDelete === idActual ){
                                resetForm(modelForm);
                            }               
                                // volver a cargar los registros en el datatable              
                                const registros = await getRegistros(null, urls, idPadre);   
                                if( registros !== null ){
                                    saveRegistros(registros);     
                                }

                                if( borrandoHijo === true ){
                                    // Si estamos editando un hijo volvemos a cargar los datos del formulario del padre
                                    await handleEditRegPrin(Number(idPadre), urlsPadre, null, false, null, tablaPadre);
                                    await loadHijos(Number(idPadre), urls, modelForm);
                                }

                                saveAdjuntosPrin([]);
                                saveAdjuntosHijo([]);
                        }else{
                            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.logic.delete.error.title}`, {autoClose: true});
                        }
                    }else if( typeof respuesta.data === "string" ){            
                            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});                
                    }       
                }

                let idRegDelete;
                let idActual;
                if( typeof e == "undefined" ){
                    if( formulario.id === "" ){
                        alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.delete.empty.title}`, {autoClose: true});
                        return;
                    }else{
                        idRegDelete = formulario.id;
                    }
                }else{

                    if( e.target.classList.contains("deleteRow") ){
                        idRegDelete = e.target.id;
                            if( borrandoPadre ){
                                idActual = document.querySelector(".idPadre").value;
                            }
                            if( borrandoHijo ){
                                idActual = document.querySelector(".idHijo").value;
                            }
                    }
                } 

                // Comprobamos con la bbdd la si la concurrencia esta activada ya que cuando entramos directamente en un registro enviándole como parámetro en la url su id, o abrimos otra pestaña de la misma pantalla esté dato no está disponible, mientras que si hacemos una navegación normal por los menús si que está disponible y ahí ya no hace falta consultarlo.
                let concurrenciaAux;
                if( concurrencia === "" ){
                    saveLoadReg(true);
                    concurrenciaAux = await checkConcurencia();
                }
                if( concurrencia === "1" || concurrenciaAux === "1" ){
                    if( socket != null && socket.connected == true ){
                        //Comprobamos que el registro no esté en edición por ningun usuario para poder eliminarlo
                        // Envío una consulta al servidor con mis datos y los del registro que quiero editar para consultar si esta libre
                        socket.emit("regOcupado", { "url": window.location.origin, "idusu": cookies.get('id'), "nombre": cookies.get('nombre'), "idreg": idRegDelete, "tabla": tabla }, (response) => {
                            if( response["response"].length > 0 ){
                                alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.delete.busy.title} '${response["response"][0]["nombre"]}' - ${response["response"][0]["hora"]}`, {autoClose: true});          
        
                            }else if( response["response"].length === 0 ){
                                borrar(e, urls, formulario, resetForm, saveRegistros, idPadre, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre, tablaPadre, idRegDelete, idActual)                       
                            }
                        })  
                    }else{
                        borrar(e, urls, formulario, resetForm, saveRegistros, idPadre, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre, tablaPadre, idRegDelete, idActual)  
                    }
                }else{
                    borrar(e, urls, formulario, resetForm, saveRegistros, idPadre, modelForm, tabla, borrandoPadre, borrandoHijo, urlsPadre, tablaPadre,  idRegDelete, idActual)  
                }
                
            }
        }

        // ***** Submit del formulario ******
        const sendForm = async (e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre=null, modelForm, tabla, esHijo=false, urlsPadre=null, tablaPadre=null, modelFormHijo=null, title, noQuestion) => {

            if( e ){
                e.preventDefault();
                e.stopPropagation();
            }
    

            //Primero comprobamos que algún campo del formulario tiene algo. Lo hacemos por si un formulario no tiene ningún required evitar que metan registros en blanco
            const guardarRegistro = async (e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre=null, modelForm, tabla, esHijo, urlsPadre, tablaPadre, modelFormHijo) => {

                const enviarFormulario = async (e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre=null, modelForm, tabla, accion, esHijo, urlsPadre, tablaPadre, modelFormHijo) => {

                    // preparamos los datos del formulario para enviar
                    const data = new FormData(e.target);      
                    const validacion = apiKey();
                    data.append("validacion", validacion);

                    for(let i = 0; i < adjuntos.length; i++ ){
                        if( !adjuntos[i].urlAdd ){
                            data.append(adjuntos[i]["id"]+"[]", adjuntos[i]);
                        }
                    }

                    // enviamos la petición
                    const respuesta = await axios.post(getApiUrl(window.location.origin)+urls.submit,data,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    //Borramos el spinner
                    saveLoadReg(false);

                    if( typeof respuesta.data !== "string" ){
                        if( respuesta.data !== 0 ){
                            formulario.id = respuesta.data;

                            let mensaje = "";
                            if(accion === "crear"){
                                mensaje = confApp.config.trad.logic.send.successnew.title;
                            }else{
                                mensaje = confApp.config.trad.logic.send.successedit.title;
                            }
                            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${mensaje}`, {autoClose: true});


                            // volver a cargar los registros en el datatable
                            const registros = await getRegistros(null, urls, idPadre); 
                            saveRegistros(registros);   
                            

                            // hicimos submit con el botón de guardar y nuevo, reseteamos el formulario
                            if( e.nativeEvent.submitter.classList.contains("btnsaveAndNew") ){
                                resetForm(modelForm, true);
                            }else{
                                //Volvemos a cargar los datos del formulario     
                                editReg(formulario.id, urls, saveFormulario, saveAdjuntosExistentes, tabla, esHijo);
                                if( urls.getAdj != "" ){
                                    // Volvemos a cargar los adjuntos actuales
                                    const data = new FormData();                
                                    const validacion = apiKey();
                                    data.append("validacion", validacion);
                                    data.append("id", formulario.id);                                                                         
                                    // enviamos la petición para obtener los adjuntos del registro actual
                                    const resAdj = await axios.post(getApiUrl(window.location.origin)+urls.getAdj,data);
                                    saveAdjuntosExistentes(resAdj.data.adjuntos);
                                }

                            }
                            //Reseteamos los input de los adjuntos
                            let adjs = document.querySelectorAll("input[type=file]");            
                            for(let i=0; i<adjs.length; i++){
                                adjs[i].value = null;
                                adjs[i].files = null;
                            }

                            //Reseteamos los adjuntos pendientes de subir.
                            saveAdjuntos([]); 

                            if( esHijo === true ){
                                // Si estamos editando un hijo volvemos a cargar los datos del formulario del padre
                                await handleEditRegPrin(Number(idPadre), urlsPadre, null, false, null, tablaPadre);
                            }

                            // Si estamos creando un padre, reseteamos el formulario hijo y la tabla de registros hijos la ponemos vacía para que no queden datos si tuvimos un registro padre seleccionado anteriormente.
                            if( accion === "crear" && modelFormHijo != null ){
                                resetFormHijo(modelFormHijo);
                                saveRegistrosHijo([]);
                            }
                  
                        }else{
                            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.logic.send.successnew.title}`, {autoClose: true});
                        }
                    }else if( typeof respuesta.data === "string" ){            
                            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
                    }        
                }
                                               
                
                let valores = !Object.values(formulario).every(o => o === "");
                
                //***** Validamos que el formulario no tenga errores *****/
                let erroresForm = false; 
                for( let i = 0; i < e.target.length && erroresForm === false; i++){
                    if( e.target[i].classList.contains("is-invalid")){
                        erroresForm = true;
                    }
                }
                if( erroresForm === false ){
                    if( valores ){
        
                        //Colocamos el spinner
                        saveLoadReg(true);
        
                        let accion = null;
                        if( formulario.id === "" ){
                            accion = "crear";
                        }else{
                            accion = "modificar";
                        }
                        
                        if( accion == "modificar" ){
                                // Comprobamos con la bbdd la si la concurrencia esta activada ya que cuando entramos directamente en un registro enviándole como parámetro en la url su id, o abrimos otra pestaña de la misma pantalla esté dato no está disponible, mientras que si hacemos una navegación normal por los menús si que está disponible y ahí ya no hace falta consultarlo.
                                let concurrenciaAux;
                                if( concurrencia === "" ){
                                    saveLoadReg(true);
                                    concurrenciaAux = await checkConcurencia();
                                }
                                if( (concurrencia === "1" || concurrenciaAux === "1") && esHijo != true ){
                                    if( socket != null && socket.connected == true ){
                                        //Comprobamos que el registro no esté en edición por ningun usuario para poder eliminarlo
                                        // Envío una consulta al servidor con mis datos y los del registro que quiero editar para consultar si esta libre
                                        socket.emit("regOcupado", { "url": window.location.origin, "idusu": cookies.get('id'), "nombre": cookies.get('nombre'), "idreg": formulario.id, "tabla": tabla }, (response) => {
                                            if( response["response"].length > 0 ){
                                                
                                                //Colocamos el spinner
                                                saveLoadReg(false);
                                                alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.send.busy.title} '${response["response"][0]["nombre"]}' - ${response["response"][0]["hora"]}`, {autoClose: true});             
            
                                            }else if( response["response"].length === 0 ){
                                                enviarFormulario(e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre, modelForm, tabla, accion, esHijo, urlsPadre, tablaPadre, modelFormHijo);                       
                                            }
                                        })  
                                    }else{
                                        enviarFormulario(e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre, modelForm, tabla, accion, esHijo, urlsPadre, tablaPadre, modelFormHijo);
                                    }
                            }else{
                                enviarFormulario(e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre, modelForm, tabla, accion, esHijo, urlsPadre, tablaPadre, modelFormHijo);
                            }

                        }else if( accion == "crear" ){
                            enviarFormulario(e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre, modelForm, tabla, accion, esHijo, urlsPadre, tablaPadre, modelFormHijo);
                        }
                        
                    }else{
                        alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confApp.config.trad.logic.send.empty.title}`, {autoClose: true});
                    }                
                }else{
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.logic.send.formerror.title}`,{autoClose: true});
                }
            }


            if( noQuestion ){
                // Hacemos scroll hasta el principio de la pantalla
                $('html,body').animate({scrollTop:0},800);
                guardarRegistro(e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre, modelForm, tabla, esHijo, urlsPadre, tablaPadre, modelFormHijo);
            }else{
                swal({
                    title: title,
                    text: confApp.config.trad.logic.send.swal.title,
                    icon: "warning",
                    buttons: {
                        cancel : {text: confApp.config.trad.logic.send.swal.button.cancel.title ,className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text:confApp.config.trad.logic.send.swal.button.confirm.title ,className:'btn btn-flat mr-1 btn-outline-primary btnSwalSave'}
                    }       
                }).then(confirmacion => {
                    if(confirmacion){
                        // Hacemos scroll hasta el principio de la pantalla
                        $('html,body').animate({scrollTop:0},800);
                        guardarRegistro(e, urls, formulario, saveFormulario, resetForm, saveRegistros, adjuntos, saveAdjuntos, saveAdjuntosExistentes, idPadre, modelForm, tabla, esHijo, urlsPadre, tablaPadre, modelFormHijo);
                    }
                })
            }

           
            
        }


    /***** Fin: CRUD Functions *****/

        // ***** Resetear el formulario. Reseteamos y ponemos el id en blanco para meter un registro nuevo  *****
        const resetFormPrin = (modelFormPrin, resetSocket=false, tabla="undefined") => {

            // Liberamos el registro anterior
            if( resetSocket && concurrencia === "1" ){
                if( socket != null && socket.connected == true ){
                    socket.emit("resetForm", { "idusu": cookies.get('id'), "tabla": tabla })
                }
            }

            saveLoadReg(true);            
            saveFormularioPrin(modelFormPrin);
            saveLoadReg(false);
            saveAdjuntosPrin([]);
            saveAdjuntosExistentesPrin([]);
            saveIdPrin(null);
    
            /***** Borramos las clases de validación de los input *****/
            deleteClass("is-valid");
            deleteClass("is-invalid");
    
            //Reseteamos los input de los adjuntos
            let adjs = document.querySelectorAll("input[type=file]");            
            for(let i=0; i<adjs.length; i++){
                adjs[i].value = null;
                adjs[i].files = null;
            }

            // Hacemos scroll hasta el principio de la pantalla
            $('html,body').animate({scrollTop:0},800);
    
        }

         // ***** Resetear el formulario hijo. Reseteamos y ponemos el id en blanco para meter un registro nuevo  *****
        const resetFormHijo = (modelFormHijo) => {
                  
            saveLoadReg(true);
            saveFormularioHijo(modelFormHijo);
            saveLoadReg(false);
            saveAdjuntosHijo([]);
            saveAdjuntosExistentesHijo([]);
            saveIdHijo(null);

            /***** Borramos las clases de validación de los input *****/
            deleteClass("is-valid");
            deleteClass("is-invalid");

            //Reseteamos los input de los adjuntos
            let adjs = document.querySelectorAll("input[type=file]");
            for(let i=0; i<adjs.length; i++){
                adjs[i].value = null;
                adjs[i].files = null;
            }

            // Hacemos scroll hasta el principio de la pantalla
            $('html,body').animate({scrollTop:0},800);

        }

        // ***** Carga inicial de registros *****
        const regIniciales = async (saveRegistros, urls, idPadre=null) => {

            const registros = await getRegistros(null, urls, idPadre);  
            if( registros ){
                if( registros !== null ){
                    saveRegistros(registros);
                }
            }else{
                saveRegistros([]);
            }
    
        }
    
        // ***** Obtener registros y guardarlos en el state para que actualice el datatable *****
        const getRegistros = async (id=null, urls, idPadre=null) => {

            const data = new FormData();
            if(id){
                data.append("id", id);
            }
            if(idPadre){
                data.append("idPadre", idPadre);
            }            
            let filtro = "";
            if( cookies.get('filterRegs') != undefined ){
                filtro = cookies.get('filterRegs');
            }          
            if( filterRegs != "" ){
                filtro = filterRegs
            }
            if( filtro != "" ){
                data.append("filter", filtro);
            }

            const validacion = apiKey();
            data.append("validacion", validacion);
            const respuesta = await axios.post(getApiUrl(window.location.origin)+urls.get, data);
    
            if( typeof respuesta.data !== "string" && respuesta.data[0] !== undefined ){                               
                return respuesta.data;
            }else if( typeof respuesta.data === "string" ){            
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
                return null;
            }
        }

        /***** Función que valida que un nombre de usuario esté repetido en otro registro *****/
        const validateUser = async (e, idReg) => {
            if( e.target.value !== ""){
                const userValido = await checkUser(e.target.value, idReg);    
                setDeleteInputError(userValido, e.target);
            }else{
                e.target.classList.remove("is-valid");
                e.target.classList.remove("is-invalid");
            }
        }

        /***** Función que valida que un email de usuario esté repetido en otro registro *****/
        const validateEmail = async (e, idReg) => {
            if( e.target.value !== ""){
                const userValido = await checkEmail(e.target.value, idReg);    
                setDeleteInputError(userValido, e.target);
            }else{
                e.target.classList.remove("is-valid");
                e.target.classList.remove("is-invalid");
            }
        }



    return{
        registrosPrin, 
        saveRegistrosPrin,
        formularioPrin, 
        saveFormularioPrin,
        adjuntosPrin, 
        saveAdjuntosPrin,
        adjuntosExistentesPrin, 
        saveAdjuntosExistentesPrin,
        registrosHijo, 
        saveRegistrosHijo, 
        formularioHijo, 
        saveFormularioHijo, 
        adjuntosHijo, 
        saveAdjuntosHijo, 
        adjuntosExistentesHijo, 
        saveAdjuntosExistentesHijo,
        handleInputForm,
        handleLastReg,
        handleSelectFormPrin,
        handleSelectFormHijo,
        handleSelectMultipleFormPrin,
        handleSelectMultipleFormHijo,
        handleEditRegPrin,
        loadingTable, 
        saveLoadingTable, 
        loadReg, 
        saveLoadReg, 
        idPrin, 
        saveIdPrin, 
        idHijo, 
        saveIdHijo,
        editReg,
        regIniciales,
        getRegistros,
        deleteItem,
        sendForm,
        validateUser, 
        validateEmail,
        handleEditRegHijo,
        handleDeleteItemPrin, 
        handleDeleteItemHijo, 
        handleSubmitPrin, 
        handleSubmitHijo,
        resetFormPrin,
        resetFormHijo,
        showChangePass, 
        saveShowChangePass,
        handleCheckUser, 
        handleCheckEmail, 
        handleChangePass,
        imgCabecera, 
        saveImgCabecera,
        filterRegs, 
        saveFilterRegs,
        handleSelectFormPrinAndSetValue,
        handleSelectFormHijoAndSetValue,
        handleClonePrin,
        handleCloneHijo,
        loadHijos,
        loadedHijos, 
        saveLoadedHijos
    }
}
export default useLogicForm
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';
import { HashLink as Link } from 'react-router-hash-link';
import { getApiUrl } from '../../../helpers/helperApp';


const url = window.location;

function MenuSuperAdmin({ miNivel, activeMenuColor, confApp }){



    return(
              
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" role="menu" data-accordion="true">
                <li className="nav-item">
                    <NavLink to="/alarmasmo" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft : "10px"}} >
                        <i className={confApp.argosAlarmas.alarmasmo.sidebarmenu.icono} />
                        <p>{confApp.argosAlarmas.alarmasmo.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>          
            </ul>
        </nav>                          

       

    )
}

export default MenuSuperAdmin;
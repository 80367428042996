import React, { useState, useEffect, useContext } from 'react';

import { LoginContext } from '../../../hooks/context/LoginContext';


import imageCompression from "browser-image-compression";
import axios from 'axios';
import { SRLWrapper } from "simple-react-lightbox";
import swal from 'sweetalert';

import { getApiUrl, axiosPost } from '../../../helpers/helperApp';

import { Fragment } from 'react';
import Cookies from 'universal-cookie';


function Upls(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { id, value, save, formulario, profile, readonly, estadoForm, loading, traduction } = props;

    const _URL = window.URL || window.webkitURL;

    // ***** States *****
    const [validacionesUpls, saveValidacionesUpls] = useState([]);
    const [controlWidth, saveControlWidth] = useState("");
    const [controlHeight, saveControlHeight] = useState("");

    // ***** Context *****
    const { apiKey } = useContext(LoginContext);

    useEffect( () => {
        // ***** Carga inicial de validaciones *****       
        if( validacionesUpls.length === 0 && cookies.get('id') ){
            const data = new FormData(); 
            const validacion = apiKey();
            data.append("validacion", validacion);
            const getValidacionesUpls = async () => {
                await axios.post(getApiUrl(window.location.origin)+"/adj/getValidaciones", data)
                // await axiosPost(getApiUrl(window.location.origin)+"/adj/getValidaciones", data, source.token)
                .then( response => {
                    if( typeof response.data !== "string" ){
                        saveValidacionesUpls(response.data);
                        saveControlWidth(response.data[3]["val"].split("x")[0]);
                        saveControlHeight(response.data[3]["val"].split("x")[1]);
                    }
                    // else{
                    //     swal("Error", `${traduction.loadvalidationserror.title}`, "error");
                    // }
                })
                .catch( error => {
                    swal("Error", `${traduction.loadvalidationserror.title}`, "error");
                })
            }

            getValidacionesUpls();
        }
        
        return ( () => {
            saveValidacionesUpls([]);             
            // Token cancelación axios al desmontar componentes
            source.cancel();    
        })

    },[])

    // ***** Leer los archivos del input *****
    const handleFile = async file => {
        await actionsFile(file[0]);
    }

    const handleDelete = e => {
        e.preventDefault();
        document.querySelector(`input[name="${id}"]`).value = "";
        save({ ...formulario, [id.split(".")[1]]: null });

    }
 
    const actionsFile = async file => {

        let extensiones = validacionesUpls[0]["val"].split("|");
        let maxWidth = parseInt(validacionesUpls[1]["val"].split("x")[0]);
        
        const optionsCompres = {          
            maxSizeMB: validacionesUpls[2]["val"],
            maxWidthOrHeight: maxWidth,
            useWebWorker: false
        }       
        
            
        let valido = true;
        //Validamos la extensión
        if( !validateExtension(file, extensiones) ){
            swal("Error", `${file["name"]} ${traduction.errorextension.title}`, "error");
            valido = false;
        }

        if( file["type"].split("/")[0] === "image" ){
            //Imágenes                
                //Hacemos el resize para las imágenes                    
                let fileResize = await imageCompression(file, optionsCompres); 
                let aux = await blobToFile(fileResize, file);

                aux.src = _URL.createObjectURL(aux);
                save({ ...formulario, [id.split(".")[1]]: aux.src });
        }else{                                   
            swal("Error", `${traduction.onlyimage.title}`, "error");   
            document.querySelector(`input[name="${id}"]`).value = "";                 
        }

    }

    const validateExtension = (file, extensiones) => {
        let fileExtension = file["name"].split(".")[1];
        const valido = extensiones.find(ext => fileExtension.toLowerCase() == ext.toLowerCase());
        return valido;
    }

    const blobToFile = (blob, file) => {
        blob.lastModifiedDate = file.lastModifiedDate;
        blob.name = file.name;
        return new File([blob], file.name,{type: "image/jpeg", lastModified: file.lastModifiedDate });     
    }
    


    return(
        <Fragment>
            {
                !profile
                ?
                    <div style={{width: controlWidth+"px",height: controlHeight+"px", margin: "0px 10px 10px 10px", position: "relative", border: "1px solid #ced4da", padding: "5px",  position: "relative", display: "inline-block" }}>
                            <input 
                                id={id} 
                                type="file" 
                                name={id} 
                                onChange={ (e) => handleFile(e.target.files) } 
                                style={{display: "none"}}
                            />
                            <input id={'fileExist**'+id} type="hidden" name={'fileExist**'+id} value={value} />
                            {
                                readonly !== "true" && readonly !== "readonly" && !loading && estadoForm !== "readonly" && estadoForm !== "disabled"
                                ?
                                <div>
                                    {
                                        value !== "" && value !== null && value !== undefined
                                        ?
                                            <label className="uplsDelete" onClick={ e =>  handleDelete(e) }  ></label>
                                        :
                                            <label htmlFor={id} className="uplsEdit"></label>
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        value !== "" && value !== null && value !== undefined
                                        ?
                                            <label className="disable-click transparent uplsDelete" onClick={ e =>  handleDelete(e) }  ></label>
                                        :
                                            <label htmlFor={id} className="disable-click transparent uplsEdit"></label>
                                    }
                                </div>
                            }
                            
                        
                            <div style={{width: "100%", height: "100%", overflow: "hidden" }}>
                            {
                                value !== "" && value !== null && value !== undefined
                                ?
                                    <SRLWrapper><div className="containerImg"><img className="imgUpls" style={{}} src={value} /></div></SRLWrapper>
                                :
                                    null
                            }
                            </div>
                    </div>
                :
                <Fragment>
                    {
                        readonly !== "true" && readonly !== "readonly" && !loading && estadoForm !== "readonly" && estadoForm !== "disabled"
                        ?
                        <div>
                        {
                            value !== "" && value !== null && value !== undefined
                            ?
                                <label className="uplsDelete" onClick={ e =>  handleDelete(e) }  ></label>
                            :
                                <label htmlFor={id} className="uplsEdit"></label>
                        }
                        </div>
                        :
                        <div>
                        {
                            value !== "" && value !== null && value !== undefined
                            ?
                                <label className="disable-click transparent uplsDelete" onClick={ e =>  handleDelete(e) }  ></label>
                            :
                                <label htmlFor={id} className="disable-click transparent uplsEdit"></label>
                        }
                        </div>
                    }                    
                    <div style={{width: "120px",height: "120px", marginLeft: "-15px", marginTop: "-15px", position: "relative", border: "1px solid #ced4da", position: "relative", display: "inline-block", borderRadius: "60px", backgroundColor: "white", overflow: "hidden" }}>
                            <input 
                                id={id} 
                                type="file" 
                                name={id} 
                                onChange={ (e) => handleFile(e.target.files) } 
                                style={{display: "none"}}
                            />
                            <input id={'fileExist**'+id} type="hidden" name={'fileExist**'+id} value={value} />
                            
                            <div style={{width: "100%", height: "100%", overflow: "hidden" }}>
                            {
                                value !== "" && value !== null && value !== undefined
                                ?
                                    <img className="" style={{width: "100%",height: "100%"}} src={value} />
                                :
                                    // <div style={{width: "100%",height: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "#343a40"}} ><i className="fal fa-user-astronaut fa-6x"></i></div>
                                    <img className="" style={{width: "100%",height: "100%"}} src={getApiUrl(window.location.origin)+"/assets/web/avatar.png"} />
                            }
                            </div>
                    </div>
                </Fragment>
            }
        </Fragment>                       
    )
}

export default Upls;
import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import { getApiUrl, axiosPost } from '../../helpers/helperApp';


function Notifications({confApp}){

    // Token cancelación axios
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { apiKey, forceGlobalReload, saveForceGlobalReload } = useContext(LoginContext);

    const[showNotification, saveShowNotifications] = useState(false)
    const[numNotifications, saveNumNotifications] = useState(0);


    useEffect(()=>{

        getDataNotifications();

        return () => {
            // Token cancelación axios al desmontar componentes
            source.cancel();
        };

    },[forceGlobalReload])

    const getDataNotifications = async () => {
        const data = new FormData();
        data.append("validacion", apiKey());
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confApp.argosAlarmas.notifications.getdata, data, source.token);
        saveNumNotifications(respuesta.data);
        saveShowNotifications(true);
    }

    
    return(
            showNotification
            ?
                <li className="nav-item dropdown notifications">
                    <NavLink to="/inicio" className="nav-link" data-toggle="dropdown">
                        <i className="fal fa-exclamation-triangle" style={{fontSize: "1.25em"}}></i>
                        <span className="badge badge-danger navbar-badge">{numNotifications}</span>
                    </NavLink>
                    {/* <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" className="dropdown-item">
                        <div className="media">
                        <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                        <div className="media-body">
                            <h3 className="dropdown-item-title">
                            Brad Diesel
                            <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                            </h3>
                            <p className="text-sm">Call me whenever you can...</p>
                            <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                        </div>
                        </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                    </div> */}
                </li>
            :
                null
    )
}

export default Notifications;
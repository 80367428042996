import React,{ useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { alertService } from '../../../services/alertservice';
import { Alert } from '../../ui/Alert';
import Spinner from 'react-bootstrap/Spinner';
import ButtonOutline from '../../../componentes/ui/ButtonOutline';

import { LoginContext } from '../../../hooks/context/LoginContext';

import useLogicForm from '../../../hooks/useLogicForm';


import 'bootstrap/dist/css/bootstrap.min.css';

import { getApiUrl, axiosPost } from '../../../helpers/helperApp';

function FormChangePass({idUsuario, traduction, props}){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const {  validateSesion, validaPassword, formChangePass, saveFormChangePass } = useContext(LoginContext); 

    // ***** Custom Hook - Lógica de los formularios *****
    const {  loadReg, saveLoadReg } = useLogicForm();

    
    const [userPassValido, saveUserPassValid] = useState("");
    const [userRepetirPassValido, saveRepetirUserPassValid] = useState("");
    const [confirmChange, saveConfirmChange] = useState({
        estado: false, clases: "", mensaje: "", icono: ""
    });

    // ***** Funciones que se ejecutan al terminar  de cargar el componente *****
    useEffect(() => {
                
            // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
            validateSesion(props, null);        

            if( idUsuario === "" || idUsuario === null ){
                alertService.error(`${traduction.nouser.title}`,{autoClose: false});
            }

            return () =>{    
                // Token cancelación axios al desmontar componentes
                source.cancel();
            }

    }, [])

    // ***** Lama a la api para comprobar username y password, almacena en las cookies la información del usuario *****
    const handleChangePass = async (e) =>{

        e.preventDefault();
        if( userPassValido === "is-valid" && userRepetirPassValido === "is-valid" ){
            saveLoadReg(true);
            const data = new FormData();
            data.append("info", idUsuario);
            data.append("pass", formChangePass.pass);            

            // const url = `${getApiUrl(window.location.origin)}/login/userChangePass`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(getApiUrl(window.location.origin)+'/login/userChangePass', data, source.token);            
            if( res.data === 0 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${traduction.error.title}`,{autoClose: true});
            }else if( res.data === 1 ){
                saveConfirmChange({...confirmChange, estado: true, clases: "success", mensaje: traduction.success.title, icono: "fa-check-circle mr1" })                
            }
            saveLoadReg(false);
        }else{
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${traduction.formerror.title}`,{autoClose: true});
        }

    }

    // ***** Llama la función de validación del password y almacena en el state del form los valores de username y password *****
    const handleChange = e => {
        if( e.target.name === "pass" ){
            validaPassword(e.target.value);
        }

        const { name, value } = e.target;
        saveFormChangePass({
            ...formChangePass,
            [name]: value
        })
    }
    
    const handleValidaPass = async e => {

        if( e.target.value !== ""){
            const errorValidacion = validaPassword(e.target.value);
            if( errorValidacion !== false ){
                saveUserPassValid("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
            }else{
                saveUserPassValid("is-valid");
            }
        }else{
            saveUserPassValid("");
            saveRepetirUserPassValid("");
        }

    }

    const handleValidaRepetirPass = async e => {

        if( formChangePass.pass != "" ){
            if( formChangePass.pass !== formChangePass.repetirPassword ){
                saveUserPassValid("is-invalid");
                saveRepetirUserPassValid("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${traduction.passerror.title}`,{autoClose: true});
            }else{
                const errorValidacion = validaPassword(formChangePass.pass);
                if( errorValidacion !== false ){
                    saveUserPassValid("is-invalid");
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
                }else{
                    saveUserPassValid("is-valid");
                    saveRepetirUserPassValid("is-valid");
                }
            }
        }else{
            saveRepetirUserPassValid("");
        }
        if( formChangePass.repetirPassword === "" && formChangePass.pass === "" ){
            saveRepetirUserPassValid("");
            saveUserPassValid("");
        }

    }



    return(
      <div>

                    {
                        confirmChange.estado === false
                        ?
                        <div>
                        <div style={{ marginTop:5, marginBottom:10, textAlign: "center"}} >       
                            {/* <Alert /> */}
                                {   loadReg ? 
                                    <Spinner animation="border" />
                                    :
                                    null
                                }
                        </div>                       
                            <form onSubmit={ handleChangePass }>
                                <div className="input-group mb-3">
                                        <input 
                                            type="password" 
                                            className={`form-control ${userPassValido}`} 
                                            placeholder={traduction.inputpass.placeholder} 
                                            autoComplete="off"                                
                                            name="pass" 
                                            onChange={ handleChange }
                                            onBlur={ handleValidaPass }
                                            required
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                </div>
                                <div className="input-group mb-3">
                                        <input 
                                            type="password" 
                                            className={`form-control ${userRepetirPassValido}`} 
                                            placeholder={traduction.inputrepeat.placeholder}
                                            autoComplete="off"                                
                                            name="repetirPassword" 
                                            onChange={ handleChange }
                                            onBlur={ handleValidaRepetirPass }
                                            required
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                </div>
                                <div className="row">
                                <div className="col-12">
                                    <ButtonOutline 
                                        clases="col-12"
                                        color="primary"  
                                        type="submit" 
                                        icono="fal fa-unlock-alt" 
                                        id=""     
                                        texto={traduction.btnsubmit.title} 
                                        disabled={ idUsuario === "" || idUsuario === null ? true : false }
                                        accion={ null } 
                                     />
                                </div>
                                </div>
                            </form>
                        </div>
                        :
                        <div className={"alert alert-"+confirmChange.clases} role="alert">
                            <h6><i className={"fal "+confirmChange.icono+" mr-1"}></i>{confirmChange.mensaje}</h6>
                        </div>
                    }

      </div>
    )
}

export default FormChangePass;